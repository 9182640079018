import { useEffect, useRef, useCallback, useState } from 'react';
import { initializeCursor } from '../canvas';

interface ZoomPanOptions {
  minZoom?: number;
  maxZoom?: number;
  zoomStep?: number;
  panningEnabled?: boolean;
}

interface UseContainerZoomProps {
  containerRef: React.RefObject<HTMLDivElement>;
  fabricRef: React.RefObject<fabric.Canvas>;
  options?: ZoomPanOptions;
}

export const useContainerZoom = ({
  containerRef,
  fabricRef,
}: UseContainerZoomProps) => {
  const isDragging = useRef(false);
  const lastPosX = useRef(0);
  const lastPosY = useRef(0);
  const spacePressed = useRef(false);

  const [zoom, setZoom] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const defaultOptions = {
    minZoom: 0.3,
    maxZoom: 5,
    zoomStep: 0.1,
    panningEnabled: true,
  };

  const updateTransform = useCallback(() => {
    if (!containerRef.current) return;
    containerRef.current.style.transform = `translate(${position.x}px, ${position.y}px) scale(${zoom})`;
  }, [zoom, position, containerRef]);

  const zoomToPoint = useCallback(
    (point: { x: number; y: number }, newZoom: number) => {
      if (!containerRef.current) return;

      if (newZoom === 1) {
        setPosition({ x: 0, y: 0 });
        setZoom(1);
        return;
      }

      if (!point) {
        point = {
          x: containerRef.current.clientWidth / 2,
          y: containerRef.current.clientHeight / 2,
        };
      }

      const constrainedZoom = Math.min(
        Math.max(defaultOptions.minZoom!, newZoom),
        defaultOptions.maxZoom!
      );

      const container = containerRef.current.getBoundingClientRect();
      const dx = point?.x - container.left;
      const dy = point?.y - container.top;

      const scaleChange = constrainedZoom / zoom;
      const newX = position.x - dx * (scaleChange - 1);
      const newY = position.y - dy * (scaleChange - 1);

      setZoom(constrainedZoom);
      setPosition({ x: newX, y: newY });
    },
    [
      zoom,
      position,
      defaultOptions.minZoom,
      defaultOptions.maxZoom,
      containerRef,
    ]
  );

  const handleMouseWheel = useCallback(
    (e: WheelEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const delta = e.deltaY;
      const newZoom = zoom * 0.999 ** delta;

      const point = {
        x: e.clientX,
        y: e.clientY,
      };

      zoomToPoint(point, newZoom);
    },
    [zoom, zoomToPoint]
  );

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (!spacePressed.current || !defaultOptions.panningEnabled) return;

      isDragging.current = true;
      if (containerRef.current) {
        containerRef.current.style.cursor = 'grabbing';
        initializeCursor(fabricRef.current!, 'grabbing');
      }

      lastPosX.current = e.clientX;
      lastPosY.current = e.clientY;
    },
    [defaultOptions.panningEnabled, fabricRef]
  );

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDragging.current) return;
      initializeCursor(fabricRef.current!, 'grabbing');
      const dx = e.clientX - lastPosX.current;
      const dy = e.clientY - lastPosY.current;

      setPosition((prev) => ({
        x: prev.x + dx,
        y: prev.y + dy,
      }));

      lastPosX.current = e.clientX;
      lastPosY.current = e.clientY;
    },
    [fabricRef]
  );

  const handleMouseUp = useCallback(() => {
    isDragging.current = false;
    if (containerRef.current) {
      if (!spacePressed.current) return;
      initializeCursor(fabricRef.current!, 'grab');
      containerRef.current.style.cursor = spacePressed.current
        ? 'grab'
        : 'default';
    }
  }, [fabricRef, containerRef, spacePressed]);

  const panning = useCallback((activated: boolean) => {
    spacePressed.current = activated;
    const canvas = fabricRef.current;
    if (containerRef.current) {
      containerRef.current.style.cursor = activated ? 'grab' : 'default';
      if (activated) {
        initializeCursor(canvas!, 'grabbing');
      }
    }
  }, []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Space' && !spacePressed.current) {
        spacePressed.current = true;
        const canvas = fabricRef.current;
        if (containerRef.current) {
          containerRef.current.style.cursor = 'grab';
          initializeCursor(canvas!, 'grab');
        }
      }
    },
    [containerRef, fabricRef]
  );

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        spacePressed.current = false;
        const canvas = fabricRef.current;
        isDragging.current = false;
        if (containerRef.current) {
          containerRef.current.style.cursor = 'default';
          initializeCursor(canvas!);
        }
      }
    },
    [fabricRef, containerRef]
  );

  const resetView = useCallback(() => {
    setZoom(1);
    setPosition({ x: 0, y: 0 });
  }, []);

  useEffect(() => {
    updateTransform();
  }, [zoom, position, updateTransform]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.style.transformOrigin = '0 0';

    container.addEventListener('wheel', handleMouseWheel, { passive: false });

    container.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      container.removeEventListener('wheel', handleMouseWheel);
      container.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [
    containerRef,
    handleMouseWheel,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleKeyDown,
    handleKeyUp,
  ]);

  return {
    zoom,
    position,
    resetView,
    panning,
    zoomToPoint,
  };
};
