import React, { act, useCallback, useEffect } from 'react';
import { fabric } from 'fabric';
import { useCanvasStore } from '@/state/useStore';

export const useActiveImage = ({
  fabricRef,
  handleToolSelection,
  activeTool,
  setBlock,
  setBound,
}: {
  fabricRef: React.MutableRefObject<fabric.Canvas | null>;
  path?: React.MutableRefObject<any>;
  handleToolSelection: any;
  activeTool: { value: string; attributes?: any };
  setBlock: any;
  setBound: any;
}) => {
  /**
   * Stores the active image reference
   */
  const activeImageRef = React.useRef<
    | fabric.Image
    | fabric.Rect
    | fabric.Circle
    | fabric.Triangle
    | fabric.Text
    | null
  >(null);
  const groupRef = React.useRef<fabric.Group | null>(null);
  const imagePreviewRef = React.useRef<fabric.Image | null>(null);
  const maskCanvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const { setCanvasLoading } = useCanvasStore();
  /**
   * if any proposed tool decides to add an offscreen path
   * this will be used to store the path
   */
  const pathRef = React.useRef<any>(null);

  const handleMouseDown = useCallback(
    (options: any) => {
      const canvas = fabricRef.current;
      const isAllLayers = activeTool?.attributes?.layer === 'all';

      if (!canvas) return;

      if (isAllLayers) {
        canvas.getObjects().forEach((obj) => {
          if (obj.type === 'group' || obj.type === 'path') {
            obj.set({
              visible: false,
            });
          }
        });

        canvas.requestRenderAll();

        const image = canvas.toDataURL({ format: 'png' });
        fabric.Image.fromURL(image, (img) => {
          img.set({
            left: 0,
            top: 0,
            width: canvas.width,
            height: canvas.height,
          });

          activeImageRef.current = img;
        });

        setBlock('image', image);

        canvas.getObjects().forEach((obj) => {
          if (obj.type === 'group' || obj.type === 'path') {
            obj.set({
              visible: true,
            });
          }
        });

        canvas.requestRenderAll();
        return;
      }

      let clickedImage = canvas.findTarget(options.e, false) as any;

      if (clickedImage?.type !== 'image') {
        const objects = canvas.getObjects();
        const nearestImage = objects
          .filter((obj) => obj.type === 'image')
          .map((obj) => ({
            obj,
            distance: Math.hypot(
              obj.left! - options.e.offsetX,
              obj.top! - options.e.offsetY
            ),
          }))
          .sort((a, b) => a.distance - b.distance);

        if (nearestImage.length > 0) {
          clickedImage = nearestImage[0].obj;
        }
      }

      if (
        clickedImage &&
        (clickedImage.type === 'image' || !!clickedImage?.src)
      ) {
        activeImageRef.current = clickedImage;
        try {
          setBlock('image', clickedImage?.toDataURL({ format: 'png' }));
        } catch (e) {
          setBlock('image', null);
        }
      }
    },
    [fabricRef, activeImageRef, activeTool?.attributes]
  );

  const resetActiveImage = useCallback(
    (softReset?: boolean) => {
      const canvas = fabricRef.current;
      if (!canvas) return;

      if (imagePreviewRef.current) {
        canvas.remove(imagePreviewRef.current);
        imagePreviewRef.current = null;
      }

      if (softReset) return;

      if (pathRef.current) {
        canvas.remove(pathRef.current);
        pathRef.current = null;
      }

      if (groupRef.current) {
        canvas.remove(groupRef.current);
        groupRef.current = null;
      }

      if (activeImageRef.current) {
        activeImageRef.current = null;
      }

      if (maskCanvasRef.current) {
        maskCanvasRef.current.remove();
        maskCanvasRef.current = null;
      }

      setBound({
        top: 0,
        left: 0,
        show: false,
      });
      setBlock({ reset: true });
    },
    [fabricRef, imagePreviewRef, pathRef, groupRef, maskCanvasRef]
  );

  const confirmPreview = useCallback(() => {
    const canvas = fabricRef.current;
    if (!canvas || !imagePreviewRef.current) return;
    canvas.remove(imagePreviewRef.current);

    /**
     * @see https://linear.app/nex-ai/issue/APP-672/p1-generating-and-inpainting-still-hides-other-layers
     */
    // if (activeImageRef.current) {
    //   (activeImageRef.current as any).set({
    //     visible: false,
    //     selectable: false,
    //   });
    // } else {
    //   canvas.getObjects().forEach((obj) => {
    //     if (obj.type === 'image') {
    //       obj.set({
    //         visible: false,
    //         selectable: false,
    //       });
    //     }
    //   });
    // }

    imagePreviewRef.current.clone((cloned: any) => {
      cloned.set({
        disableFromLayer: false,
        selectable: true,
        evented: true,
        clipPath: undefined,
      });

      canvas.add(cloned);
      canvas.bringToFront(cloned);
    });

    canvas.remove(pathRef.current);

    resetActiveImage();
    handleToolSelection({ value: 'select' });

    canvas.requestRenderAll();
  }, [fabricRef]);

  const createPreview = useCallback(
    (imageURl?: string) => {
      const canvas = fabricRef.current;

      if (!canvas) return;
      setCanvasLoading(true);
      const reset = () => {
        if (imagePreviewRef.current) {
          canvas.remove(imagePreviewRef.current);
          canvas.requestRenderAll();
        }
      };

      if (
        imagePreviewRef.current &&
        (imagePreviewRef.current as any).get('src') === imageURl
      ) {
        reset();
        imagePreviewRef.current = null;
        setCanvasLoading(false);
        canvas.requestRenderAll();
        return;
      }

      let combinedPathString;
      let clipPath = null;
      const zoom = canvas.getZoom();

      let imageData = {
        width: canvas.width! * zoom,
        height: canvas.height! * zoom,
        left: 0,
        top: 0,
      };

      if (
        groupRef.current &&
        groupRef.current.type === 'group' &&
        groupRef.current.getObjects().length > 0
      ) {
        // empty i don't know what Bing wants to do here
      } else {
        combinedPathString = pathRef.current?.path;
      }

      if (combinedPathString) {
        clipPath = new fabric.Path(combinedPathString, {
          absolutePositioned: true,
          fill: 'transparent',
          stroke: 'transparent',
        });
      }

      const image = new Image();
      image.src = imageURl!;
      image.crossOrigin = 'anonymous';

      if (activeImageRef.current) {
        imageData = {
          width: activeImageRef.current.width! * activeImageRef.current.scaleX!,
          height:
            activeImageRef.current.height! * activeImageRef.current.scaleY!,
          left: activeImageRef.current.left!,
          top: activeImageRef.current.top!,
        };
      }

      image.onload = () => {
        reset();

        imagePreviewRef.current = new fabric.Image(image, {
          left: imageData.left,
          top: imageData.top,
          width: imageData.width,
          height: imageData.height,
          disableFromLayer: true,
          backgroundColor: 'transparent',
          clipPath: clipPath,
          src: imageURl,
        } as any);

        canvas.add(imagePreviewRef.current);

        setCanvasLoading(false);
        canvas.renderAll();

        image.remove();
      };
    },
    [fabricRef, activeImageRef, pathRef, imagePreviewRef]
  );

  const createInitialMask = useCallback(async () => {
    return new Promise((resolve, reject) => {
      if (!activeImageRef.current || !pathRef.current) return;
      const scaledWidth =
        activeImageRef.current.width! * activeImageRef.current.scaleX!;
      const scaledHeight =
        activeImageRef.current.height! * activeImageRef.current.scaleY!;
      const left = activeImageRef.current?.left;
      const top = activeImageRef.current?.top;

      const maskType = activeTool?.value;

      console.log('maskType', maskType);
      switch (maskType) {
        case 'magic-select':
          return generateWithOverlayMask();
        case 'lasso':
          return generateWithClipPath();
        default:
          return generateWithFill();
      }

      function generateWithOverlayMask() {
        if (maskCanvasRef.current) {
          const image = new Image();
          image.src = maskCanvasRef.current.toDataURL();

          const offScreenMask = new OffscreenCanvas(scaledWidth, scaledHeight);
          const ctx = offScreenMask.getContext('2d');
          const convertToUsableMask = () => {
            if (!ctx) return;

            ctx.drawImage(image, 0, 0);
            const imageData = ctx.getImageData(
              0,
              0,
              offScreenMask.width,
              offScreenMask.height
            );
            const data = imageData.data;

            for (let i = 0; i < data.length; i += 4) {
              const alpha = data[i + 3];
              data[i + 3] = 255;

              if (alpha > 0) {
                data[i] = 255;
                data[i + 1] = 255;
                data[i + 2] = 255;
              } else {
                data[i] = 0;
                data[i + 1] = 0;
                data[i + 2] = 0;
              }
            }

            ctx.putImageData(imageData, 0, 0);
          };

          image.crossOrigin = 'anonymous';
          return (image.onload = async () => {
            convertToUsableMask();
            const maskURI = URL.createObjectURL(
              await offScreenMask.convertToBlob()
            );

            return resolve(maskURI);
          });
        }
      }

      function generateWithClipPath() {
        const clipPath = new fabric.Path(pathRef.current.path, {
          absolutePositioned: true,
          fill: 'transparent',
          stroke: 'transparent',
          fillRule: 'evenodd',
        });

        const maskRect = new fabric.Rect({
          width: scaledWidth,
          height: scaledHeight,
          fill: 'white',
          fillRule: 'evenodd',
          clipPath: clipPath,
        });

        const imageMask = new fabric.Image(maskRect.toCanvasElement(), {
          left: left,
          top: top,
          width: scaledWidth,
          erasable: false,
          height: scaledHeight,
          backgroundColor: 'black',
        } as any);

        const maskURI = imageMask?.toDataURL({ format: 'png' });

        return resolve(maskURI);
      }

      function generateWithFill() {
        if (groupRef.current) {
          const backgroundRect = new fabric.Rect({
            width: scaledWidth,
            height: scaledHeight,
            fill: 'transparent',
            left: left,
            top: top,
          });
          return groupRef.current.clone((cloned: any) => {
            cloned.addWithUpdate(backgroundRect);
            cloned.forEachObject((path: any) => {
              if (path.type === 'path') {
                path.set({
                  stroke: 'white',
                });
              }
            });

            const dataUrl = cloned.toDataURL({
              format: 'png',
            });

            const image = new Image();
            image.src = dataUrl;
            image.onload = () => {
              const imageMask = new fabric.Image(image, {
                left: left,
                top: top,
                width: scaledWidth,
                height: scaledHeight,
                erasable: false,
                backgroundColor: 'black',
              } as any);

              const maskURI = imageMask?.toDataURL({ format: 'png' });
              return resolve(maskURI);
            };
          });
        }
      }
    });
  }, [activeImageRef, pathRef, activeTool?.value]);

  useEffect(() => {
    const canvas = fabricRef.current;

    if (!canvas) return;

    if (activeTool?.value && activeTool?.value === 'select') return;

    canvas.on('mouse:down', handleMouseDown);
    return () => {
      canvas.off('mouse:down', handleMouseDown);
    };
  }, [fabricRef, activeTool?.value, handleMouseDown]);

  const createImage = useCallback(
    (type: 'all' | 'selected') => {
      const canvas = fabricRef.current;

      if (!canvas) return;

      let objects: any = canvas;

      if (type === 'selected') {
        const selectedObjects = canvas.getActiveObjects();
        if (selectedObjects.length === 0) {
          objects = canvas;
          return;
        }
        objects = new fabric.Group(
          selectedObjects.map(
            (obj) =>
              obj.clone((cloned: any) => {
                if (cloned.type === 'activeSelection') {
                  cloned.set({
                    left: cloned.left + 10,
                    top: cloned.top + 10,
                  });
                }
              }) as any
          ),
          {
            originX: 'center',
            originY: 'center',
            disableFromLayer: true,
            hasControls: false,
            hasBorders: false,
            selectable: false,
            evented: false,
          } as any
        );
      }

      const _img = new Image();
      _img.src = objects.toDataURL({ format: 'png', multiplier: 1 });
      _img.crossOrigin = 'anonymous';

      _img.onload = () => {
        // const image = new fabric.Image(_img, {
        //   left: 0,
        //   top: 0,
        //   width: objects.width * objects.scaleX * canvas.getZoom(),
        //   height: objects.height * objects.scaleY * canvas.getZoom(),
        // });

        setBlock('image', _img.src);
        canvas.renderAll();
      };

      canvas.renderAll();
    },
    [fabricRef]
  );

  return {
    activeImageRef,
    groupRef,
    maskCanvasRef,
    resetActiveImage,
    confirmPreview,
    createImage,
    createPreview,
    createInitialMask,
    pathRef,
  };
};
