import React, { useEffect, useMemo } from 'react';
import Router from 'next/router';
import classNames from 'classnames';

import { ArtboardAsidePreview, ArtboardSidebar } from '@/features/console';

import { useArtboardStore, useUserStore } from '@/state/useStore';
import { TutorialOnboarding } from '@/components/tutorials';
import {
  Tutorials,
  TUTORIAL_STORAGE_KEY,
} from '@/components/tutorials/constants';
import { CropModal } from '@/features/console/artboard/components/modals/crop-modal';
import { useLocalStorage, useMediaQuery } from '@nex/labs';
import { usePostHog } from 'posthog-js/react';
import { FabricProvider } from '../provider/fabric-provider';

import { usePusher } from '@/features/console/artboard/hooks/usePusher';
import { useArtboardGenerate } from '@/features/console/artboard/hooks';
import { AuthModal } from '@/features/auth/auth-modal';
import { useUser } from '@clerk/nextjs';
import { useGetModelsQuery } from '@/state/query/block';

import styles from './artboard-layout.module.scss';

export const ArtboardLayout = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const {
    isRealTime,
    artboard,
    setLayoutBar,
    setActiveTab,
    activeTab,
    authModal,
    setAuthModal,
    resetArtboard,
    setConfig,
    setSnapshots,
    defaultConfig,
    models,
    setModels,
  } = useArtboardStore();

  const { profile } = useUserStore();
  const { user } = useUser();

  const normalizeGenerate = useArtboardGenerate();
  usePusher();

  const { data: modelData, refetch } = useGetModelsQuery(
    {},
    {
      enabled: !models.length,
      select: (data) => {
        return {
          models: data.models.map((model: any) => ({
            ...model,
            blocks: model.blocks
              .filter((blck: any) => blck.name !== 'preset')
              .map((block: any) => block.name),
          })),
        };
      },
      onSettled: (data) => {
        if (data) {
          setModels(data?.models);
        }
      },
    }
  );

  useEffect(() => {
    if (!modelData) refetch();
  }, [modelData]);

  useEffect(() => {
    if (modelData) {
      if (!defaultConfig.model?.id) {
        setConfig({
          model: modelData?.models.find((model: any) => !!model.default),
        });
      }
    }
  }, [defaultConfig.model?.id, modelData]);

  useEffect(() => {
    const artboardSnapShot = window.sessionStorage.getItem('artboardSnapShot');

    if (artboardSnapShot) {
      const { blocks, config } = JSON.parse(artboardSnapShot);
      setSnapshots({ blocks, config });
      window.sessionStorage.removeItem('artboardSnapShot');
    }
  }, []);

  const [tutorial, setTutorial] =
    useLocalStorage<Record<string, boolean | string>>(TUTORIAL_STORAGE_KEY);

  const isMobile = useMediaQuery('lg', 'greaterThan');

  const isSketch = useMemo(() => {
    return activeTab === 'sketch';
  }, [activeTab]);

  useEffect(() => {
    if (Router.query.sketchId) {
      setActiveTab('sketch', { disableRoute: true });
    }
  }, [Router.query.sketchId]);

  useEffect(() => {
    if (
      artboard?.id ||
      artboard?.compositions?.length ||
      Router?.query?.id === 'new'
    ) {
      resetArtboard();
    }
  }, [Router?.query?.id]);

  useEffect(() => {
    if (!Router.asPath.includes('/artboard')) return;
    if (isSketch) return;
    //check if CMD + enter or CTRL + enter is pressed
    const handleKeyDown = (e: any) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        // check if the active element is an input field or textarea
        if (
          [HTMLInputElement, HTMLTextAreaElement].includes(
            e.target.constructor
          ) ||
          (e.key === 'Enter' && (e.metaKey || e.ctrlKey))
        ) {
          e.preventDefault();
          return normalizeGenerate();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [normalizeGenerate, isSketch]);

  const posthog = usePostHog();

  return (
    <div
      className={classNames([
        styles.ArtboardLayout,
        isRealTime && styles.isRealtime,
      ])}
    >
      <FabricProvider initialize={isSketch}>
        <ArtboardSidebar />

        <main className={styles.ArtboardMain}>{children}</main>

        {!(typeof tutorial === 'string' || tutorial?.artboard) &&
          !isMobile &&
          (profile?.id || user) && (
            <TutorialOnboarding
              tutorials={Tutorials}
              onProceed={(key: string) => {
                if (['done', 'skip'].includes(key)) {
                  if (key === 'done') {
                    posthog.capture('tutorial_completed', {
                      tutorial: 'artboard',
                    });
                  } else {
                    posthog.capture('tutorial_skipped', {
                      tutorial: 'artboard',
                    });
                  }

                  const prev = () => {
                    if (typeof tutorial === 'string') {
                      return {
                        artboard: new Date().toISOString(),
                      };
                    }

                    return tutorial;
                  };

                  return setTutorial({
                    ...tutorial,
                    artboard: new Date().toISOString(),
                  });
                }

                if (key === 'preview') {
                  return setLayoutBar('preview');
                }

                setActiveTab(key);
              }}
            />
          )}

        <ArtboardAsidePreview />
      </FabricProvider>
      <CropModal />
      <AuthModal
        show={authModal}
        onClose={() => {
          setAuthModal(false);
        }}
      />
    </div>
  );
};
