import React from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import {
  CallToAction,
  Flex,
  isPremiumUser,
  pluralize,
  Spinner,
  Text,
  useMediaQuery,
  useLocalStorage,
  Tooltip,
} from '@nex/labs';

import Router from 'next/router';
import LifeBuoy from '@nex/icons/svg/misc/life-bouy.svg';
import { CompositionStatus } from '@nex/types/sketch';

import LogoMark from '@nex/icons/svg/logo-mark.svg';
import PresetIcon from '@nex/icons/svg/blocks/preset.svg';
import AssetIcon from '@nex/icons/svg/blocks/assets.svg';
import PromptIcon from '@nex/icons/svg/blocks/prompt.svg';
import RocketIcon from '@nex/icons/svg/blocks/rocket.svg';
import SketchIcon from '@nex/icons/svg/blocks/sketch.svg';
import PreviewIcon from '@nex/icons/svg/blocks/preset.svg';

import { useArtboardStore } from '@/state/useStore';

import { INNER_PAGES } from '../../views';
import { useArtboardGenerate } from '../../hooks';
import { useUserStore } from '@/state/useStore';

import { useFabric } from '@/components/layout';
import { useUser } from '@clerk/nextjs';

import styles from './sidebar.module.scss';
import {
  TUTORIAL_STORAGE_KEY,
  TUTORIALS,
} from '@/components/tutorials/constants';

export const ArtboardSidebar = () => {
  const {
    activeTab,
    blockInView,
    setActiveTab,
    setLayoutBar,
    layoutBar,
    requests,
    setAuthModal,
    isGenerating,
    isRealTime,
  } = useArtboardStore();
  const { handleCanvasChange } = useFabric();
  const { profile } = useUserStore();
  const { user } = useUser();
  const isMobile = useMediaQuery('lg', 'greaterThan');
  const normalizeGenerate = useArtboardGenerate();

  return (
    <>
      <div
        className={classNames([
          styles.ArtboardAsideOverlay,
          layoutBar === 'block' && styles.isShow,
        ])}
        onClick={() => setLayoutBar('')}
      />
      <motion.div
        className={classNames([
          styles.ArtboardAside,
          isRealTime && styles.realtime,
          layoutBar === 'block' && styles.isShow,
        ])}
        id="artboard-aside"
      >
        <nav className={styles.ArtboardAsideNav}>
          <div className={styles.topNav}>
            <Link href="/" className="w-fit mb-[18px]">
              <LogoMark width="32" height="32" />
            </Link>

            {[
              { icon: PromptIcon, label: 'Models', key: 'prompt' },
              { icon: AssetIcon, label: 'Assets', key: 'assets' },
              { icon: SketchIcon, label: 'Collages', key: 'sketch' },
            ]
              .filter((item) => {
                const nonRealtimeTabs = ['preset', 'sketch'];
                if (isRealTime) {
                  return nonRealtimeTabs.includes(item.key);
                }

                return true;
              })
              .map((item, index) => {
                const shouldNotWorkOnCampaigns =
                  ['assets', 'sketch'].includes(item.key) &&
                  !(profile?.id || user);
                return (
                  <button
                    key={index}
                    className={classNames([
                      styles.NavButton,
                      activeTab === item.key && styles.active,
                    ])}
                    onClick={async () => {
                      if (shouldNotWorkOnCampaigns) {
                        return setAuthModal(true);
                      }
                      await handleCanvasChange('');
                      setActiveTab(item.key);
                    }}
                  >
                    <item.icon />
                    {item.label}
                  </button>
                );
              })}
          </div>
          {(profile?.id || user) && !isPremiumUser(profile) && (
            <div className={styles.bottomNav}>
              <button onClick={() => Router.push('/settings/plans')}>
                <RocketIcon />
                Upgrade
              </button>
            </div>
          )}
        </nav>
        <div className={styles.ArtboardBlocksInnerPage}>
          <Flex.Column
            gap={12}
            justifyContent="space-between"
            className="h-full"
          >
            <div className={styles.BlockInnerPageScroll}>
              <CallToAction.button
                size="xs"
                variant="secondary"
                onClick={() => setLayoutBar('')}
                className="flex lg:hidden ml-auto"
              >
                Close
              </CallToAction.button>
              <ArtboardInnerPages />
            </div>
          </Flex.Column>
        </div>
      </motion.div>
    </>
  );
};

const ArtboardInnerPages = () => {
  const { activeTab } = useArtboardStore();
  const [tutorial, setTutorial] = useLocalStorage(TUTORIAL_STORAGE_KEY);

  return (
    <>
      <Flex.Row
        gap={12}
        className="mb-2"
        fullWidth
        justifyContent="space-between"
      >
        <Text weight={800}>NEX STUDIO</Text>
        {!!tutorial && (
          <motion.button
            onClick={() => {
              setTutorial({ ...tutorial, [TUTORIALS.ARTBOARD]: false });
              Router.reload();
            }}
          >
            {' '}
            <Tooltip tooltip="Open onboarding tutorial">
              <LifeBuoy width={14} className="opacity-60" />
            </Tooltip>
          </motion.button>
        )}
      </Flex.Row>
      <motion.div
        className={styles.ArtboardInnerPages}
        initial={{ opacity: 0, x: -5 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        key={activeTab}
      >
        {INNER_PAGES.map((page, index) => {
          if (page.key === activeTab) {
            if (page.el) return <page.el key={index} />;
            return null;
          }
        })}
      </motion.div>
    </>
  );
};
