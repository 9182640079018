import React, { useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Modal, transitions } from '@nex/labs';

import queryString from 'query-string';

import { useRouter } from 'next/router';
import { useGlobalStore } from '@/state/useStore';

import { AssetsModal } from '@/features/console';
import { ProUpSell } from '@/features/console/settings';
import { GenerateTutorial } from '../modals/tutorial';

import { CreateWorkspaceModal } from '../views/console-layout/component/create-workspace';

enum ModalTypes {
  result = 'result',
  collection = 'collection',
  assets = 'assets',
  tutorial = 'tutorial',
  pricing = 'pricing',
  createWorkspace = 'createWorkspace',
}

export const GlobalModal = () => {
  const [show, setShow] = React.useState<string | null>(null);
  const modalRef = useRef(null);
  const router = useRouter();
  const { modal, closeModal: hideModal } = useGlobalStore();
  const modalType = modal?.type;
  const modalProps = modal?.props as {
    title: string;
    link: string;
    size: 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg';
    onClose: () => void;
    bare: boolean;
    dismissible: boolean;
  };

  useEffect(() => {
    if (modalType) {
      setShow(modalType);
    } else {
      setShow(null);
    }
  }, [modalType]);

  useEffect(() => {
    if (router?.query?.view === 'preset' || router?.query?.success) {
      setShow((router?.query?.view as string) ?? 'success');
    }
  }, [router?.query?.view, router?.query?.success]);

  useEffect(() => {
    if (router.query?.watch === 'tutorial') {
      setShow(ModalTypes.tutorial);
    }
  }, [router.query?.watch]);

  const closeModal = async () => {
    setShow(null);

    const replace = queryString.stringifyUrl({
      url: window.location.pathname ?? router?.asPath ?? '/',
      query: {
        ...router.query,
        view: undefined,
        success: undefined,
        watch: undefined,
      },
    });

    if (router?.query.redirectTo) {
      return router.push(router?.query.redirectTo as string);
    }

    if (router.query || Object.keys?.(router?.query)?.length > 2) {
      await router.push(replace, replace, {
        shallow: true,
      });
    }

    hideModal();
  };

  const getFadeIn = transitions.useSlide();

  const getModalComponent = (modalType: string) => {
    switch (modalType) {
      case ModalTypes.assets:
        return <AssetsModal />;
      case ModalTypes.pricing:
        return <ProUpSell />;
      case ModalTypes.createWorkspace:
        return <CreateWorkspaceModal />;
      case ModalTypes.tutorial:
        return (
          <GenerateTutorial title={modalProps?.title} link={modalProps?.link} />
        );
      default:
        return null;
    }
  };

  if (!!!show) {
    return null;
  }

  return (
    <>
      <Modal
        in={!!show}
        onClose={async () => {
          if (modalProps?.onClose) {
            try {
              await modalProps?.onClose();
              closeModal();
            } catch (error) {
              console.error(error);
            }
          } else closeModal();
        }}
        title={modalProps?.title ?? router?.query?.view ?? ''}
        ref={modalRef}
        size={modalProps?.size ?? 'lg'}
        bare={modalProps?.bare}
        dismissible={modalProps?.dismissible}
      >
        <AnimatePresence mode="sync">
          <motion.div
            key={show}
            {...getFadeIn({
              y: 40,
            })}
          >
            {getModalComponent(show)}
          </motion.div>
        </AnimatePresence>
      </Modal>
    </>
  );
};
