import { fabric } from 'fabric';
import { generateStaticID as uuidv4 } from '@nex/labs';

import {
  CustomFabricObject,
  ElementDirection,
  ImageUpload,
  ModifyShape,
} from '@nex/types/sketch';
import axios from 'axios';
import { DISABLE_EVENTS_ON_ACTIVE_ELEMENT } from './utils';
import { useAppState } from '@/state/store';

export const createRectangle = (pointer: PointerEvent) => {
  const rect = new fabric.Rect({
    left: pointer.x,
    top: pointer.y,
    width: 100,
    height: 100,
    fill: '#D9D9D9',
    strokeWidth: 0,
    objectId: uuidv4(),
    erasable: false,
  } as CustomFabricObject<fabric.Rect>);

  return rect;
};

export const createTriangle = (pointer: PointerEvent) => {
  return new fabric.Triangle({
    left: pointer.x,
    top: pointer.y,
    width: 100,
    height: 100,
    strokeWidth: 0,
    fill: '#D9D9D9',
    objectId: uuidv4(),
    erasable: false,
  } as CustomFabricObject<fabric.Triangle>);
};

export const createCircle = (pointer: PointerEvent) => {
  return new fabric.Circle({
    left: pointer.x,
    top: pointer.y,
    strokeWidth: 0,
    radius: 100,
    fill: '#D9D9D9',
    objectId: uuidv4(),
    erasable: false,
  } as any);
};

export const createLine = (pointer: PointerEvent) => {
  return new fabric.Line(
    [pointer.x, pointer.y, pointer.x + 100, pointer.y + 100],
    {
      stroke: '#D9D9D9',
      strokeWidth: 2,
      objectId: uuidv4(),
      erasable: false,
    } as CustomFabricObject<fabric.Line>
  );
};

export const createText = (pointer: PointerEvent, text: string) => {
  return new fabric.IText(text, {
    left: pointer.x,
    top: pointer.y,
    fill: '#D9D9D9',
    fontFamily: 'Helvetica',
    fontSize: 36,
    fontWeight: '400',
    objectId: uuidv4(),
    erasable: false,
  } as fabric.ITextOptions);
};

export const createSpecificShape = (
  shapeType: string,
  pointer: PointerEvent
) => {
  switch (shapeType) {
    case 'rectangle':
      return createRectangle(pointer);

    case 'triangle':
      return createTriangle(pointer);

    case 'circle':
      return createCircle(pointer);

    case 'line':
      return createLine(pointer);

    case 'text':
      return createText(pointer, 'Tap to Type');

    default:
      return null;
  }
};
const requestQueue: any[] = [];
export const toDataUrl = async (url: string, callback: any) => {
  try {
    if (requestQueue.length) {
      requestQueue.push({ url, callback });
      return;
    }

    if (url.startsWith('data:image')) {
      callback(url);
      return;
    }

    const response = await axios.get(
      `/api/s3/?url=${encodeURIComponent(url)}`,
      {
        responseType: 'blob',
      }
    );

    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };

    reader.readAsDataURL(response.data);
  } catch (e) {
    console.error(e);
  } finally {
    if (requestQueue.length) {
      const { url, callback } = requestQueue.shift();
      toDataUrl(url, callback);
      return;
    }
  }
};

export const handleImageUpload = ({
  file,
  canvas,
  imageKey,
  fillCanvas,
  shapeRef,
  syncShapeInStorage,
}: ImageUpload) => {
  const activeTool = useAppState.getState().canvas.activeTool;
  const layers = useAppState.getState().canvas.layers;
  const readImage = async (file: File | string) => {
    const renderCanvas = (loadedImage: any) => {
      const canvasWidth = canvas.current?.getWidth() || 0;
      const canvasHeight = canvas.current?.getHeight() || 0;

      if (fillCanvas && !layers.length) {
        const scaleX = canvasWidth / loadedImage.width / 1.5;
        const scaleY = canvasHeight / loadedImage.height / 1.5;
        const scale = Math.max(scaleX, scaleY);

        loadedImage.scale(scale);

        loadedImage.set({
          left: (canvasWidth - loadedImage.width * scale) / 2,
          top: (canvasHeight - loadedImage.height * scale) / 2,
        });
      } else {
        loadedImage.scaleToHeight(
          Math.min(loadedImage?.height || 250, canvasHeight / 2)
        );
        loadedImage.set('left', 0);
        loadedImage.set('top', 0);
        loadedImage.scaleToWidth(
          Math.min(loadedImage?.width || 250, canvasWidth / 2)
        );
      }

      loadedImage.set('crossOrigin', 'Anonymous');
      loadedImage.set('erasable', false);

      if (DISABLE_EVENTS_ON_ACTIVE_ELEMENT.includes(activeTool.value)) {
        loadedImage.set({
          selectable: false,
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true,
          lockRotation: true,
        });
      }

      if (imageKey) loadedImage.set('imageKey', imageKey);

      if (loadedImage) {
        if (canvas.current) {
          canvas.current.add(loadedImage);
        }

        shapeRef.current = loadedImage;

        syncShapeInStorage(loadedImage);
        if (canvas.current) {
          canvas.current.requestRenderAll();
        }
      }
    };

    try {
      const imageHost = process.env.NEXT_PUBLIC_CANVAS_HOST;
      const img = new Image();
      const src = imageKey ? `${imageHost}${imageKey}` : file;

      img.src = src as string;
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        const loadedImage = new fabric.Image(img, {
          objectId: uuidv4(),
          erasable: false,
          imageKey,
          left: 0,
          top: 0,
          crossOrigin: 'Anonymous',
        } as any);

        renderCanvas(loadedImage);
      };
    } catch (e) {
      console.error(e);
    }
  };

  if (!file) return;

  if (typeof file === 'string') {
    return readImage(file);
  }

  const reader = new FileReader();

  reader.onload = () => {
    readImage(reader.result as string);
  };

  reader.readAsDataURL(file);
};

export const createShape = (
  canvas: fabric.Canvas,
  pointer: PointerEvent,
  shapeType: string
) => {
  if (shapeType === 'freeform') {
    canvas.isDrawingMode = true;
    return null;
  } else {
    canvas.isDrawingMode = false;
  }

  return createSpecificShape(shapeType, pointer);
};

export const modifyShape = ({
  canvas,
  property,
  value,
  activeObjectRef,
  syncShapeInStorage,
}: ModifyShape) => {
  const selectedElement = canvas.getActiveObject();

  if (!selectedElement || selectedElement?.type === 'activeSelection') return;
  if (!isNaN(Number(value))) {
    value = Number(value);
  }
  // if  property is width or height, set the scale of the selected element
  if (property === 'width') {
    selectedElement.set('scaleX', 1);
    selectedElement.set('width', value);
  } else if (property === 'height') {
    selectedElement.set('scaleY', 1);
    selectedElement.set('height', value);
  } else {
    if (selectedElement[property as keyof object] === value) return;

    //check if the string value is a number

    selectedElement.set(property as keyof object, value);
  }

  if (selectedElement) {
    activeObjectRef.current = selectedElement;
    canvas.renderAll();
    syncShapeInStorage(selectedElement);
  }
};

export const bringElement = ({
  canvas,
  direction,
  syncShapeInStorage,
}: ElementDirection) => {
  if (!canvas) return;

  const selectedElement = canvas.getActiveObject();

  if (!selectedElement || selectedElement?.type === 'activeSelection') return;

  if (direction === 'front') {
    canvas.bringToFront(selectedElement);
  } else if (direction === 'back') {
    canvas.sendToBack(selectedElement);
  }

  canvas.renderAll();

  (canvas as any)
    .getObjects()
    .sort((a: any, b: any) => a.get('zIndex') - b.get('zIndex'));
  syncShapeInStorage(selectedElement);

  canvas.renderAll();
};
