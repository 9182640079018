import { Field, Flex, Text, useMediaQuery, Avatar, Popover } from '@nex/labs';
import Link from 'next/link';
import classNames from 'classnames';

import Home from '@nex/icons/svg/console/home.svg';
import Library from '@nex/icons/svg/console/library.svg';
import Explore from '@nex/icons/svg/console/explore.svg';
import Search from '@nex/icons/svg/misc/search.svg';

import styles from './header-nav-menu.module.scss';
import Router from 'next/router';

const MENU_ITEMS = [
  {
    label: 'Create',
    Icon: Home,
    href: '/console',
  },
  {
    label: 'Library',
    Icon: Library,
    href: '/library',
  },
  {
    label: 'Learn',
    Icon: Explore,
    href: 'https://www.nex.art/news',
  },
  {
    label: 'Search',
    Icon: Search,
    href: '/search',
  },
];

type HeaderNavMenuProps = {
  className?: string;
  hideLabel?: boolean;
};

export const HeaderNavMenu = (props: HeaderNavMenuProps) => {
  return (
    <Flex className={classNames(styles.HeaderNavMenu, props.className)}>
      {MENU_ITEMS.map((item, index) => (
        <Link
          key={item.label}
          href={item.href}
          className={classNames([
            styles.HeaderNavMenuItem,
            Router.asPath.startsWith(item.href) ||
            (index === 0 && Router.asPath === '/')
              ? 'opacity-100'
              : 'opacity-60',
          ])}
        >
          <item.Icon width="24" height="24" />
          <Text
            className={classNames(
              styles.HeaderNavMenuItemLabel,
              props.hideLabel && 'hidden'
            )}
            weight={600}
          >
            {item.label}
          </Text>
        </Link>
      ))}
    </Flex>
  );
};
