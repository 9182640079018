import React, { useCallback, useEffect } from 'react';
import { fabric } from 'fabric';
import { useCanvasStore } from '@/state/useStore';
import { handleCanvasPopup } from '../canvas';

interface FeatherPaintProps {
  /**
   * The fabric canvas
   */
  fabricRef: React.MutableRefObject<fabric.Canvas | null>;
  /**
   * The active element in the canvas
   * @type {string} value - The active element
   * @type {any} attributes - The attributes of the active element
   */
  activeTool: { value: string; attributes?: any };
  /**
   * The active image in the canvas
   */
  createInitialMask: () => void;
  /**
   * The drawn objects in the canvas
   */
  pathRef: React.MutableRefObject<any>;
  /**
   * The current group in the canvas
   */
  groupRef: React.MutableRefObject<fabric.Group | null>;
  /**
   * Clear all selection in the canvas
   */
  clearAllSelection: (value?: boolean) => void;
}

export const useFeatherPaint = ({
  fabricRef,
  activeTool,
  pathRef: drawnObjects,
  groupRef: currentGroup,
  clearAllSelection,
}: FeatherPaintProps) => {
  const { setPopoverBounds: setBound } = useCanvasStore();

  const activatePaint = useCallback(() => {
    const canvas = fabricRef.current;
    const patternCanvas = (fabric as any).document.createElement('canvas');

    if (!canvas) return;

    if (currentGroup.current) {
      canvas.setActiveObject(currentGroup.current);
      canvas.requestRenderAll();
    }

    const checkerPatternBrush = new (fabric as any).PatternBrush(canvas, {
      erasable: true,
    });

    checkerPatternBrush.getPatternSrc = function () {
      patternCanvas.width = patternCanvas.height = 20;
      const ctx = patternCanvas.getContext('2d');

      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, 20, 20);

      ctx.fillStyle = '#cccccc';
      ctx.fillRect(0, 0, 10, 10);
      ctx.fillRect(10, 10, 10, 10);

      return patternCanvas;
    };

    checkerPatternBrush.color = 'rgba(0,0,0,0)';

    canvas.freeDrawingBrush = checkerPatternBrush;
    (canvas.freeDrawingBrush as any).source =
      checkerPatternBrush.getPatternSrc();
    canvas.freeDrawingBrush.color = 'rgba(0,0,0,0)';
  }, [fabricRef]);

  const handlePathCreation = useCallback(
    (e: fabric.IEvent<MouseEvent> | any) => {
      const canvas = fabricRef.current;

      if (!canvas || !e.path) return;

      if (!Array.isArray(drawnObjects.current)) {
        drawnObjects.current = [];
      }

      drawnObjects.current.push(e.path as unknown as fabric.Path);

      if (
        (currentGroup.current && currentGroup.current.type !== 'group') ||
        !currentGroup.current
      ) {
        currentGroup.current = new (fabric as any).Group([], {
          selectable: true,
          hasControls: false,
          hasRotatingPoint: false,
          disableFromLayer: true,
          border: '1px solid #000',
          erasable: 'deep',
        });

        canvas.add(currentGroup.current!);
      }

      canvas.setActiveObject(currentGroup.current!);
      canvas.renderAll();
    },
    [fabricRef]
  );

  const unGroup = useCallback(() => {
    const canvas = fabricRef.current;

    if (!canvas || !currentGroup.current) return;

    let group = currentGroup.current;
    let objects = group.getObjects();

    objects.forEach((obj) => {
      group.removeWithUpdate(obj);
      canvas.add(obj);
    });
  }, [fabricRef]);

  const addToGroup = useCallback(() => {
    const canvas = fabricRef.current;

    if (!canvas || !currentGroup.current) return;

    unGroup();

    drawnObjects.current.forEach((obj: any) => {
      canvas.remove(obj);
      currentGroup.current!.addWithUpdate(obj);
    });

    const { top, left } = handleCanvasPopup({
      canvas,
      activeObj: currentGroup.current!,
    });

    setBound({
      top,
      left,
      show: true,
    });

    canvas.renderAll();
  }, [fabricRef, setBound, unGroup]);

  const resetPaint = useCallback(() => {
    const canvas = fabricRef.current;

    if (!canvas) return;

    clearAllSelection();

    canvas.renderAll();
  }, [fabricRef, drawnObjects]);

  useEffect(() => {
    const canvas = fabricRef.current;

    if (
      activeTool?.value !== 'freeform' ||
      activeTool?.attributes?.mode !== 'brush' ||
      !canvas
    )
      return;
    canvas.on('path:created', handlePathCreation);
    canvas.on('mouse:up', addToGroup);
    return () => {
      canvas.off('path:created', handlePathCreation);
      canvas.off('mouse:up', addToGroup);
    };
  }, [
    fabricRef,
    handlePathCreation,
    addToGroup,
    activeTool?.value,
    activeTool?.attributes?.mode,
  ]);

  return { activatePaint, resetPaint };
};
