import { SKAttributes, Attributes } from '@nex/types/sketch';
import { MyStateCreator } from './store';
import { ASSETS_CONSTANTS } from '@/features/console/artboard/utils/constants';
import { Tutorials } from '@/components/tutorials/constants';

type DefaultConfig = {
  size: number;
  raw: boolean;
  resolution: string;
  model: any;
  modelBlocks?: any;
};

type Block = {
  meta: string;
  subMeta?: string;
  data: any;
};

export type ArtboardSlice = {
  activeTab: string;
  blockInView: Block[];
  requests: any[];
  remix: any;
  isRealTime: boolean;
  isGenerating: boolean;
  currentGeneration: any;
  defaultConfig: DefaultConfig;
  collaborators: any[];
  layoutBar: string;
  modal: any;
  models: any[];
  authModal: boolean;
  artboard: any;
  currentTutorial: number;
  actions: {
    setActiveTab: (tab: string, options?: { disableRoute?: boolean }) => void;
    setBlock: (
      meta: string,
      data: any,
      options?: { withRemix?: boolean }
    ) => void;
    removeBlock: (meta: string, subMeta?: string) => void;
    setCollaborators: (collaborators: any[]) => void;
    resetBlocks: () => void;
    setModal: (modal: any) => void;
    setCurrentGeneration: (generation: any) => void;
    setLayoutBar: (bar: string) => void;
    closeModal: () => void;
    setConfig: (config: Partial<DefaultConfig>) => void;
    setRequests: (request: any, options?: { override: boolean }) => void;
    removeRequest: (request: any) => void;
    setModels: (models: any[]) => void;
    setArtboard: (artboard: any) => void;
    reuseBlock: (data: any) => void;

    setIsGenerating: (isGenerating: boolean) => void;

    resetArtboard: () => void;
    setCurrentTutorial: (tutorial?: number) => void;
    setAuthModal: (authModal: boolean) => void;
    setSnapshots: (snapshots: {
      blocks: Block[];
      config: DefaultConfig;
    }) => void;
  };
};

const initialState: Omit<ArtboardSlice, 'actions'> = {
  activeTab: 'prompt',
  blockInView: [],
  requests: [],
  currentTutorial: 0,
  remix: null,
  isRealTime: false,
  isGenerating: false,
  currentGeneration: null,
  defaultConfig: {
    size: 4,
    raw: false,
    resolution: '576x860',
    model: {},
  },
  models: [],
  collaborators: [],
  layoutBar: '',
  modal: null,
  artboard: null,
  authModal: false,
};

export const createArtboardSlice: MyStateCreator<ArtboardSlice> = (
  set,
  get
) => ({
  ...initialState,

  actions: {
    setActiveTab: (tab: string) =>
      set((state) => {
        const allowedTabs = ['prompt', 'sketch', 'assets', 'preset'];
        const newTab = allowedTabs.includes(tab) ? tab : '';
        const currentTutorial = Tutorials.findIndex(
          (tutorial) => tutorial.tab === newTab
        );

        state.artboard.layoutBar = 'block';
        state.artboard.activeTab = newTab || 'prompt';

        if (currentTutorial !== -1) {
          state.artboard.currentTutorial = currentTutorial;
        }
      }),
    setModels: (models) => {
      set((state) => {
        state.artboard.models = models;
      });
    },
    setAuthModal: (authModal) => {
      set((state) => {
        state.artboard.authModal = authModal;
      });
    },
    setBlock: (meta, data, options) =>
      set((state) => {
        if (!options?.withRemix) {
          state.artboard.remix = null;
        }
        const index = state.artboard.blockInView.findIndex(
          (block) =>
            (block.data?.subMeta === data?.subMeta && data?.subMeta) ||
            (block.meta === meta && !data?.subMeta)
        );

        if (index !== -1) {
          state.artboard.blockInView[index] = { meta, data };
        } else {
          state.artboard.blockInView = [
            ...state.artboard.blockInView,
            { meta, data },
          ];
        }
      }),

    removeBlock: (meta, subMeta) =>
      set((state) => {
        console.log('state.artboard.blockInView', meta, subMeta);
        state.artboard.blockInView = state.artboard.blockInView.filter(
          (block) => {
            console.log('block', block);
            return subMeta
              ? block?.data?.subMeta !== subMeta
              : block?.meta !== meta;
          }
        );
      }),

    resetBlocks: () =>
      set((state) => {
        state.artboard.remix = null;
        state.artboard.blockInView = [];
      }),

    setModal: (modal) =>
      set((state) => {
        state.artboard.modal = modal;
      }),
    setCurrentGeneration: (generation) =>
      set((state) => {
        state.artboard.currentGeneration = generation;
      }),
    setLayoutBar: (bar) =>
      set((state) => {
        state.artboard.layoutBar = bar;
      }),
    closeModal: () =>
      set((state) => {
        state.artboard.modal = null;
      }),

    setConfig: (config) =>
      set((state) => {
        state.artboard.defaultConfig = {
          ...state.artboard.defaultConfig,
          ...config,
        };

        if (config.model) {
          // remove preset from the blocks in view
          state.artboard.blockInView = state.artboard.blockInView.filter(
            (block) => block.meta !== 'preset'
          );

          state.artboard.defaultConfig.modelBlocks = config.model.blocks;
        }
      }),

    setRequests: (request, options = { override: false }) =>
      set((state) => {
        if (options.override) {
          state.artboard.requests = request;
        } else {
          state.artboard.requests.push(request);
        }
      }),

    removeRequest: (request) =>
      set((state) => {
        state.artboard.requests = state.artboard.requests.filter(
          (rq) => rq.id !== request.id
        );
      }),

    setArtboard: (artboard) =>
      set((state) => {
        state.artboard.artboard = artboard;
      }),

    setIsGenerating: (isGenerating) => {
      set((state) => {
        state.artboard.isGenerating = isGenerating;
      });
    },
    reuseBlock: (data) => {
      const { actions } = get().artboard;
      actions.resetBlocks();

      set((state) => {
        state.artboard.remix = data;
      });

      const { model, blocks, metadata } = data;
      const { size, raw, aspectRatio } = metadata;

      actions.setConfig({
        ...get().artboard.defaultConfig,
        model:
          get().artboard.models.find((m) => m.id === model?.id) ||
          get().artboard.defaultConfig.model,
        size,
        raw,
        resolution: aspectRatio.join('x'),
      });

      Object.entries(blocks).forEach(([key, value]: [string, any]) => {
        if (!value) return;

        let val = value;
        const parent = ASSETS_CONSTANTS.find(
          (item) => item.key === key
        )?.parent;

        if (key === 'preset') {
          val = { ...value, src: value?.thumbnail };
        } else if (parent) {
          val = {
            ...value,
            src: value?.url || value?.thumbnail,
            key: value?.key,
            sketchId: value?.id,
            subMetaInfo: {
              ...ASSETS_CONSTANTS.find((item) => item.key === key),
              useAs: value?.type,
            },
            subMeta: key,
          };
        }

        actions.setBlock(parent || key, val, { withRemix: true });
      });
    },

    setCurrentTutorial: (tutorial) =>
      set((state) => {
        state.artboard.currentTutorial = tutorial!;
      }),

    setCollaborators: (collaborators) =>
      set((state) => {
        state.artboard.collaborators = collaborators;
      }),

    setSnapshots: (snapshots: { blocks: Block[]; config: DefaultConfig }) => {
      set((state) => {
        state.artboard.blockInView = snapshots.blocks;
        state.artboard.defaultConfig = snapshots.config;
      });
    },

    resetArtboard: () => {
      set((state) => {
        state.artboard.artboard = null;
        state.artboard.blockInView = [];
        state.artboard.currentGeneration = null;
        state.artboard.requests = [];
        state.artboard.remix = null;
        state.artboard.activeTab = 'prompt';
        state.artboard.defaultConfig = {
          size: 4,
          raw: false,
          resolution: '576x860',
          model: get().artboard.defaultConfig.model || {},
        };
      });
    },
  },
});
