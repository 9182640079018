import {
  Flex,
  Text,
  getDataIcons,
  useLocalStorage,
  useMediaQuery,
} from '@nex/labs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import {
  TUTORIAL_STORAGE_KEY,
  type Tutorial,
  TUTORIALS,
  Tutorials,
  TUTORIALS_KEY,
} from './constants';

import styles from './tutorial.module.scss';
import { useArtboardStore } from '@/state/useStore';

export const TutorialOnboarding = ({
  tutorials,
  onProceed,
}: {
  tutorials: Tutorial[];
  onProceed: (key: string) => void;
}) => {
  const isMobile = useMediaQuery('lg', 'greaterThan');
  const { currentTutorial, setCurrentTutorial } = useArtboardStore();
  const [_] = useLocalStorage<Record<string, boolean | string>>(
    TUTORIAL_STORAGE_KEY,
    Object.keys(TUTORIALS_KEY).reduce<Record<string, boolean | string>>(
      (acc, key) => {
        acc[key] = false;
        return acc;
      },
      {}
    )
  );
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const current = tutorials[currentTutorial];
  const popperRef = useRef<HTMLDivElement>(null);
  const getPositionByContainerId = useCallback(
    (id: string) => {
      const container = document.querySelector(id);
      if (!container || !popperRef.current) return { top: 0, left: 0 };

      const { top, left, right, bottom, width, height } =
        container.getBoundingClientRect();
      const [verticalPos, horizontalPos] = current.position.split(' ');

      const popperHeight = popperRef.current?.getBoundingClientRect().height;
      const popperWidth = popperRef.current?.getBoundingClientRect().width;

      const calcVerticalPosition = () => {
        switch (verticalPos) {
          case 'top':
            return (
              top + Math.max(0, Math.min(popperHeight - height, height)) + 12
            );
          case 'center':
            return top + height / 2;
          case 'bottom':
            return bottom - popperHeight - height - 12;
          default:
            return top + height / 2;
        }
      };

      const calcHorizontalPosition = () => {
        switch (horizontalPos || verticalPos) {
          case 'left':
            return left - 8;
          case 'center':
            return left + width / 2 - popperWidth / 2;
          case 'right':
            return right + 8;
          default:
            return left + width / 2;
        }
      };

      return {
        top: calcVerticalPosition(),
        left: calcHorizontalPosition(),
      };
    },
    [current.position, popperRef.current]
  );

  const handleProceed = (index: number) => {
    setCurrentTutorial(index);
    onProceed(tutorials[index]?.tab ?? '');
  };

  const updatePosition = useCallback(() => {
    const { containerId } = current;
    const newPosition = getPositionByContainerId(containerId);
    if (newPosition) {
      setPosition(newPosition);
    }
  }, [current, getPositionByContainerId]);

  useEffect(() => {
    updatePosition();
    // Use ResizeObserver to detect size changes of the popover
    const resizeObserver = new ResizeObserver(() => {
      updatePosition();
    });

    if (popperRef.current) {
      resizeObserver.observe(popperRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [currentTutorial, updatePosition]);

  useEffect(() => {
    const { containerId } = current;
    const { top, left } = getPositionByContainerId(containerId);
    setPosition({ top, left });
  }, [currentTutorial, current, getPositionByContainerId]);

  const getTransform = () => {
    const [vertical, horizontal] = current.position.split(' ');
    let transform = '';
    if (vertical === 'center' || (!horizontal && vertical === 'center'))
      transform += 'translateY(-50%) ';
    if (horizontal === 'center' || (!horizontal && vertical === 'center'))
      transform += 'translateX(-50%) ';
    return transform.trim();
  };

  if (isMobile) return null;

  return (
    <AnimatePresence mode="wait" initial={false}>
      <motion.div
        className={styles.TutorialOnboarding}
        initial={{ opacity: 0, scale: 0.98 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.98 }}
        transition={{ duration: 0.15 }}
        key={currentTutorial}
        style={{
          position: 'fixed',
          top: position.top,
          left: position.left,
          transform: getTransform(),
        }}
        ref={popperRef}
      >
        <div className={styles.TutorialHeader}>
          <Text weight={700} fontSize="var(--font-h6)">
            {current.title}
          </Text>
          <button className={styles.Button} onClick={() => onProceed('skip')}>
            <img src={getDataIcons('close')} />
          </button>
        </div>

        <Text>{current.message}</Text>
        {current?.image && (
          <img
            src={current.image}
            className="border-solid border-[1px] border-[var(--border-light)] rounded-[8px]"
          />
        )}

        <div className={styles.TutorialFooter}>
          {currentTutorial === tutorials.length - 1 ? (
            <button
              className="bg-[var(--primary-theme)] px-[8px] py-[4px] rounded-[4px] text-[var(--text-black)]"
              onClick={() => onProceed('done')}
            >
              <Text> Done</Text>
            </button>
          ) : (
            <button onClick={() => onProceed('skip')}>
              <Text> Skip </Text>
            </button>
          )}

          <Text weight={700} fontSize="var(--font-accent)">
            {currentTutorial + 1}/{tutorials.length}
          </Text>

          <Flex.Row gap={8}>
            <button
              className={styles.Button}
              onClick={() => handleProceed(Math.max(currentTutorial - 1, 0))}
              disabled={currentTutorial === 0}
            >
              <img
                src={getDataIcons('arrow-thin-down')}
                className="rotate-90"
              />
            </button>
            <button
              className={styles.Button}
              onClick={() =>
                handleProceed(
                  Math.min(currentTutorial + 1, tutorials.length - 1)
                )
              }
              disabled={currentTutorial === tutorials.length - 1}
            >
              <img
                src={getDataIcons('arrow-thin-down')}
                className="-rotate-90"
              />
            </button>
          </Flex.Row>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
