import { useMemo } from 'react';

// Returns the highest z-index of all threads
export const useMaxZIndex = () => {
  const maxZIndex = useMemo(() => {
    const elements = Array.from(document.querySelectorAll('.thread'));
    const zIndexes = elements.map((element) => {
      const zIndex = window.getComputedStyle(element).zIndex;
      return zIndex === 'auto' ? 0 : parseInt(zIndex, 10);
    });
    return Math.max(...zIndexes);
  }, []);

  return maxZIndex;
};
