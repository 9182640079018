import React from 'react';

import { Flex, useLocalStorage } from '@nex/labs';

import LogoMark from '@nex/icons/svg/logo-mark.svg';
import { AnimatorSidebar } from './components/sidebar/index';
import { FloatBar } from '@/components/misc/float-bar';
import Menu from '@nex/icons/svg/misc/menu-alt.svg';
import styles from './animator-layout.module.scss';
import Router from 'next/router';
import classNames from 'classnames';
import { TutorialOnboarding } from '@/components/tutorials';
import {
  ANIMATOR_TUTORIALS,
  TUTORIAL_STORAGE_KEY,
  Tutorials,
} from '@/components/tutorials/constants';
import { usePostHog } from 'posthog-js/react';

export const AnimatorLayout = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [open, setOpen] = React.useState(false);
  const [tutorial, setTutorial] =
    useLocalStorage<Record<string, boolean | string>>(TUTORIAL_STORAGE_KEY);
  const posthog = usePostHog();

  return (
    <div className={styles.AnimatorLayout}>
      <div className={classNames([styles.FloatingNav, styles.NavToolBar])}>
        <Flex className="flex lg:hidden" gap={8} alignItems="center">
          <button
            onClick={() => {
              setOpen(!open);
            }}
            className="flex items-center gap-2 rounded-sm px-[10px] py-[4px] bg-[var(--primary-gray)] text-[14px] font-[600]"
          >
            <Menu className="rotate-180 mr-2" width={14} />
            New Project
          </button>
        </Flex>
      </div>
      <div
        className={classNames([
          styles.AnimatorLayoutSidebar,
          {
            [styles.AnimatorLayoutSidebarOpen]: open,
          },
        ])}
      >
        <button onClick={() => setOpen(!open)} className={styles.Backdrop} />
        <AnimatorSidebar />
      </div>

      {!tutorial?.animator && (
        <TutorialOnboarding
          tutorials={ANIMATOR_TUTORIALS}
          onProceed={(key: string) => {
            if (['done', 'skip'].includes(key)) {
              if (key === 'done') {
                posthog.capture('animator_tutorial_completed', {
                  tutorial: 'artboard',
                });
              } else {
                posthog.capture('animator_tutorial_skipped', {
                  tutorial: 'artboard',
                });
              }

              const prev = () => {
                if (typeof tutorial === 'string') {
                  return {
                    artboard: new Date().toISOString(),
                  };
                }

                return tutorial;
              };

              return setTutorial({
                ...prev(),
                animator: new Date().toISOString(),
              });
            }
          }}
        />
      )}

      <main className={styles.AnimatorLayoutMain}>
        <FloatBar />
        {children}
      </main>
    </div>
  );
};
