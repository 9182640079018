import PanRightIcon from '@nex/icons/svg/animator/pan-right.svg';
import PanLeftIcon from '@nex/icons/svg/animator/pan-left.svg';
import TiltUpIcon from '@nex/icons/svg/animator/tilt-up.svg';
import TiltDownIcon from '@nex/icons/svg/animator/tilt-down.svg';
import DollyOutIcon from '@nex/icons/svg/animator/dolly-out.svg';
import DollyInIcon from '@nex/icons/svg/animator/dolly-in.svg';
import OrbitIcon from '@nex/icons/svg/animator/orbit.svg';
import SlowMotionIcon from '@nex/icons/svg/animator/slow-motion.svg';
import TimeLapseIcon from '@nex/icons/svg/animator/time-lapse.svg';

export type ModelIdentifier = (typeof MODELS)[number]['value'];

export const MODEL_HAIPER = 'fal-ai/haiper-video-v2/image-to-video';
export const MODEL_MINIMAX = 'fal-ai/minimax-video/image-to-video';

export const MODELS = [{ label: 'Minimax', value: MODEL_MINIMAX }];

export const CAMERA_MOVEMENTS = [
  { value: 'pan-right', label: 'Pan Right', Icon: PanRightIcon },
  { value: 'pan-left', label: 'Pan Left', Icon: PanLeftIcon },
  { value: 'tilt-up', label: 'Tilt Up', Icon: TiltUpIcon },
  { value: 'tilt-down', label: 'Tilt Down', Icon: TiltDownIcon },
  { value: 'dolly-out', label: 'Dolly Out', Icon: DollyOutIcon },
  { value: 'dolly-in', label: 'Dolly In', Icon: DollyInIcon },
  { value: 'orbit-left', label: 'Orbit Left', Icon: OrbitIcon },
  { value: 'orbit-right', label: 'Orbit Right', Icon: OrbitIcon },
];

export const SPECIAL_EFFECTS = [
  { value: 'slow-motion', label: 'Slow Motion', Icon: SlowMotionIcon },
  { value: 'time-lapse', label: 'Time Lapse', Icon: TimeLapseIcon },
];

export function getDurations(model: ModelIdentifier) {
  switch (model) {
    case MODEL_MINIMAX:
      return [{ value: '5', label: '5s' }];
    case MODEL_HAIPER:
      return [
        { value: '4', label: '4s' },
        { value: '6', label: '6s' },
      ];
    default:
      return [];
  }
}
