import { CallToAction, Flex, Text } from "@nex/labs";
import { useCallback, MouseEvent } from "react";
import styles from './radio-button.module.scss';

type RadioButtonOption = {
  value: string;
  label: string;
  Icon?: any;
}

type RadioButtonProps = {
  name: string;
  label: string;
  required?: boolean;
  options: RadioButtonOption[];
  currentValue?: string;
  onChange: (event?: {
    target: {
      name: string;
      value?: string;
    }
  }) => void;
}

export const RadioButton  = (props: RadioButtonProps) => {
  const { name, label, options, onChange, currentValue } = props;

  const handleOnSelect = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const target = event.currentTarget.closest('button');
    const value = target?.name;

    onChange(
      {
        target: {
          name,
          value: currentValue === value ? undefined : value,
        }
      }
    );
  }, [onChange, currentValue, name]);

  return (
    <Flex.Column gap={6} className={styles.RadioButton}>
      <label htmlFor={name}>
        <Text.span weight={600}>
          {label}
        </Text.span>
      </label>

      <Flex.Row gap={4}>
        {options.map((option) => {
          const isActve = currentValue === option.value;

          return (
            <div key={option.value} className={styles.RadioButtonOption}>
              <CallToAction.button
                size="xs"
                type="button"
                name={option.value}
                onClick={handleOnSelect}
                variant={isActve ? "primary" : "secondary"}
                leadingIcon={option.Icon ? <option.Icon width="18px" /> : undefined}>
                {option.label}
              </CallToAction.button>
            </div>
          )
        })}
      </Flex.Row>
    </Flex.Column>
  )
}