const adjectives = [
  'Happy',
  'Creative',
  'Energetic',
  'Lively',
  'Dynamic',
  'Radiant',
  'Joyful',
  'Vibrant',
  'Cheerful',
  'Sunny',
  'Sparkling',
  'Bright',
  'Shining',
];

const animals = [
  'Dolphin',
  'Tiger',
  'Elephant',
  'Penguin',
  'Kangaroo',
  'Panther',
  'Lion',
  'Cheetah',
  'Giraffe',
  'Hippopotamus',
  'Monkey',
  'Panda',
  'Crocodile',
];

export function generateRandomName(): string {
  const randomAdjective =
    adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomAnimal = animals[Math.floor(Math.random() * animals.length)];

  return `${randomAdjective} ${randomAnimal}`;
}

export const getShapeInfo = (shapeType: string) => {
  switch (shapeType) {
    case 'rect':
      return {
        icon: '/assets/rectangle.svg',
        name: 'Rectangle',
      };

    case 'circle':
      return {
        icon: '/assets/circle.svg',
        name: 'Circle',
      };

    case 'triangle':
      return {
        icon: '/assets/triangle.svg',
        name: 'Triangle',
      };

    case 'line':
      return {
        icon: '/assets/line.svg',
        name: 'Line',
      };

    case 'i-text':
      return {
        icon: '/assets/text.svg',
        name: 'Text',
      };

    case 'image':
      return {
        icon: '/assets/image.svg',
        name: 'Image',
      };

    case 'freeform':
      return {
        icon: '/assets/freeform.svg',
        name: 'Free Drawing',
      };

    default:
      return {
        icon: '/assets/rectangle.svg',
        name: shapeType,
      };
  }
};

export const defaultNavElement = {
  value: 'select',
};

export const DISABLE_EVENTS_ON_ACTIVE_ELEMENT = [
  'freeform',
  'lasso',
  'magic-select',
  'pan',
];

export const CURSOR = `data:image/svg+xml,%0A%3Csvg width='24' height='22' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_5032_6366)'%3E%3Cpath d='M2.46969 0.46969C2.67056 0.26882 2.96769 0.19867 3.23719 0.28851L21.2372 6.28851C21.5434 6.39059 21.75 6.6772 21.75 7.00002C21.75 7.32284 21.5434 7.60945 21.2372 7.71153L12.5929 10.5929L9.71153 19.2372C9.60945 19.5434 9.32284 19.75 9.00002 19.75C8.6772 19.75 8.39059 19.5434 8.28851 19.2372L2.28851 1.23719C2.19867 0.96769 2.26882 0.67056 2.46969 0.46969Z' fill='black'/%3E%3Cpath d='M3.07907 0.762848L3.07908 0.762852L21.0791 6.76285C21.1811 6.79687 21.25 6.89242 21.25 7.00002C21.25 7.10761 21.1811 7.20316 21.0791 7.23719C21.0791 7.23719 21.0791 7.23719 21.0791 7.23719L12.4348 10.1186L12.1976 10.1976L12.1186 10.4348L9.23719 19.0791C9.23719 19.0791 9.23719 19.0791 9.23719 19.0791C9.20316 19.1811 9.10761 19.25 9.00002 19.25C8.89242 19.25 8.79687 19.1811 8.76285 19.0791L2.76285 1.07908L2.76285 1.07907C2.73291 0.989247 2.75628 0.890203 2.82324 0.823244C2.8902 0.756284 2.98925 0.732907 3.07907 0.762848Z' stroke='%23FAFAFA'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_5032_6366' x='-1.75' y='0.25' width='27.5' height='27.5' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_5032_6366'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_5032_6366' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0`;

export function getDrawCursor(
  strokeWidth: number,
  color: string,
  type: string,
  zoom = 0.9
) {
  const size = strokeWidth * zoom * 1.5;
  const center = size / 2;

  const lineLength = size * 0.3;
  const halfLine = lineLength / 3.5;

  const circle = `
<svg height="${strokeWidth * zoom}" width="${strokeWidth * zoom}" viewBox="0 0 ${size} ${size}" fill="none" xmlns="http://www.w3.org/2000/svg">
  ${
    type === 'eraser'
      ? `<path d="M${center - halfLine} ${center}H${center + halfLine}" stroke="#888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`
      : `<path d="M${center - halfLine} ${center}H${center + halfLine}M${center} ${center - halfLine}V${center + halfLine}" stroke="#888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`
  }
  <circle cx="${center}" cy="${center}" r="${size / 6}" fill="${color}" fill-opacity="0.5" stroke="#888" stroke-dasharray="2 2"/>
</svg>`;

  return `data:image/svg+xml;base64,${window.btoa(circle)}`;
}
