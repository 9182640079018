import { Heading, Text, useToast, VisuallyHidden } from '@nex/labs';
import React, { useState, useCallback, useRef, useEffect } from 'react';

import UploadIcon from '@nex/icons/svg/blocks/upload.svg';
import { AnimatePresence, motion } from 'framer-motion';

interface DragDropFileUploadProps {
  acceptedFileTypes?: string[];
  onFileUpload: (
    files: File[],
    options?: { previews?: (string | ArrayBuffer | null)[] }
  ) => void;
  maxSize?: number;
  maxFiles?: number;
  children?: React.ReactNode;
}

export const DragDropFileUpload: React.FC<DragDropFileUploadProps> = ({
  acceptedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'],
  onFileUpload,
  maxSize = 10,
  maxFiles = Infinity,
}) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { createToast } = useToast();

  const maxSizeInBytes = maxSize * 1024 * 1024;

  const validateFile = useCallback(
    (file: File): boolean => {
      const fileErrors: string[] = [];

      if (acceptedFileTypes.length && !acceptedFileTypes.includes(file.type)) {
        fileErrors.push(`Type ${file.type} is not accepted`);
      }

      if (file.size > maxSizeInBytes) {
        fileErrors.push(`${file.name} exceeds the size limit of ${maxSize}MB`);
      }

      if (fileErrors.length) {
        createToast({
          message: fileErrors.join(', '),
          variant: 'error',
        });
        return false;
      }

      return true;
    },
    [acceptedFileTypes, maxSizeInBytes, maxSize, createToast]
  );

  const handleFiles = useCallback(
    (newFiles: File[]) => {
      if (files.length + newFiles.length > maxFiles) {
        createToast({
          message: `You can only upload a maximum of ${maxFiles} files`,
          variant: 'error',
        });
        return;
      }

      const validFiles = newFiles.filter(validateFile);
      if (validFiles.length === 0) return;

      setFiles((prevFiles) => [...prevFiles, ...validFiles]);

      const readers = validFiles.map((file) => {
        return new Promise<string | ArrayBuffer | null>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
      });

      Promise.all(readers).then((previews) => {
        onFileUpload(validFiles, { previews });
      });
    },
    [files, maxFiles, validateFile, onFileUpload, createToast]
  );

  const handleDrag = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true);
    } else if (e.type === 'dragleave') {
      setIsDragActive(false);
    }
  }, []);

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragActive(false);
      if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
        handleFiles(Array.from(e.dataTransfer.files));
      }
    },
    [handleFiles]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        handleFiles(Array.from(e.target.files));
      }
    },
    [handleFiles]
  );

  const openFileDialog = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  useEffect(() => {
    window.addEventListener('dragenter', handleDrag);
    window.addEventListener('dragover', handleDrag);
    window.addEventListener('dragleave', handleDrag);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragenter', handleDrag);
      window.removeEventListener('dragover', handleDrag);
      window.removeEventListener('dragleave', handleDrag);
      window.removeEventListener('drop', handleDrop);
    };
  }, [handleDrag, handleDrop]);

  return (
    <>
      <div onClick={openFileDialog}>
        <VisuallyHidden>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            onChange={handleChange}
            accept={acceptedFileTypes.join(',')}
          />
        </VisuallyHidden>
      </div>
      {isDragActive && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999999,
          }}
        >
          <div className="flex flex-col items-center justify-center gap-2 p-8 text-[var(--primary-white)] rounded-lg">
            <AnimatePresence>
              <motion.div
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
                transition={{
                  duration: 0.8,
                  repeat: Infinity,
                  repeatType: 'reverse',
                }}
              >
                <UploadIcon
                  width={50}
                  height={50}
                  color="var(--primary-white)"
                />
              </motion.div>
            </AnimatePresence>
            <Heading.h5 weight={800}>Drop your files here</Heading.h5>
            <Text>Release to upload</Text>
          </div>
        </div>
      )}
    </>
  );
};

export default DragDropFileUpload;
