// TODO: move to env
export const CLOUDFRONT_BASE_DISTRO_URL = process.env.NEXT_PUBLIC_CANVAS_HOST;

export function createVideoSource(paths: (string | null | undefined)[]) {
  const path = paths
    .filter(Boolean)
    .join('/')
    .replace(/\/{2,}/g, '/');

  const url = new URL(path, CLOUDFRONT_BASE_DISTRO_URL);
  return url.toString();
}
