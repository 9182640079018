import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { fabric } from 'fabric';
import {
  clearGuidelines,
  handleCanvasMouseDown,
  handleCanvasMouseMove,
  handleCanvasMouseUp,
  handleCanvasObjectModified,
  handleCanvasObjectMoving,
  handleCanvasObjectScaling,
  handleCanvasSelectionCreated,
  handleObjectSnap,
  handlePathCreated,
} from '../canvas';

import { useCanvasStore } from '@/state/useStore';
import { useFabric } from '@/components/layout';
import { generateStaticID } from '@nex/labs';

interface CanvasActionsProps {
  syncShapeInStorage?: any;
}

export const useCanvasActions = ({
  syncShapeInStorage,
}: CanvasActionsProps) => {
  const preventLayerUpdate = useRef(false);

  const {
    setCanvasData,
    canvasData: { isEditing },
    setActiveTool,
    setSelectedLayer,
    setLayers,
  } = useCanvasStore();

  const { setElementAttributes } = useCanvasStore();
  const {
    fabric: fabricRef,
    shapeRef,
    selectedShapeRef,
    activeObjectRef,
    isDrawingRef,
    handleResize,
    lasso: {
      handleOnMouseDown: handleLassoMouseDown,
      handleOnMouseMove: handleLassoMouseMove,
      handleOnMouseUp: handleLassoMouseUp,
    },
  } = useFabric();

  const handleObjectSelected = useCallback(
    (e: any) => {
      console.log('object selected', e);
      if ('selected' in e && e.selected) {
        const selectedIds = e.selected.map(
          (obj: fabric.Object & { id: string }) => obj.id
        );
        setSelectedLayer([...new Set([...selectedIds])]);
      }
    },
    [setSelectedLayer]
  );
  const getImagePreview = useCallback(async (fabricObject: any) => {
    if (fabricObject.type !== 'image') return null;

    if (fabricObject._element) {
      if (fabricObject.getSrc) {
        const src = await fabricObject.getSrc();
        return src;
      }

      const tempCanvas = new OffscreenCanvas(50, 50);
      try {
        const ctx = tempCanvas.getContext('2d');
        ctx!.drawImage(fabricObject._element, 0, 0, 50, 50);
        const dataUrl = URL.createObjectURL(
          await tempCanvas.convertToBlob({ type: 'image/png' })
        );
        return dataUrl;
      } finally {
        tempCanvas.width = 0;
        tempCanvas.height = 0;
      }
    }
    return null;
  }, []);

  const filterObjects = useCallback((obj: any) => {
    return (
      ['horizontal-', 'vertical-', 'action-popup'].every(
        (prefix) => !obj.id.startsWith(prefix)
      ) && !obj.disableFromLayer
    );
  }, []);

  const updateLayers = useCallback(async () => {
    if (!fabricRef.current) return;

    let increment = { count: 0, lastType: '' };
    (fabricRef.current as any).updateZIndices();

    const getLayersObject = async (obj: any) => {
      const objects = obj.getObjects().filter(filterObjects);

      const layerPromises = objects.map(async (obj: any, index: number) => {
        if (!obj.name) {
          if (obj.type === increment.lastType) {
            increment.lastType = '';
          }
          increment.lastType = obj.type;
        }

        obj.set('id', obj.id || generateStaticID());
        if (obj.get('objectId') === undefined) {
          obj.set('objectId', generateStaticID());
        }

        let src = null;

        if (obj.type === 'image') {
          try {
            src = await getImagePreview(obj);
          } catch (e) {
            console.error(e);
          }
        }

        const layerObj = {
          id: obj.id,
          name: !obj.name
            ? `${obj.type} ${obj.type === increment.lastType ? index || '' : ''}`
            : obj.name,
          type: obj.type,
          locked: obj.lockMovementX || obj.lockMovementY,
          zIndex: obj.zIndex,
          src,
          visible: obj.visible !== false,
          groupId: obj.groupId,
        } as any;

        if (obj.type === 'group') {
          layerObj.objects = await getLayersObject(obj);
        }

        return layerObj;
      });

      return Promise.all(layerPromises);
    };

    try {
      const layers = await getLayersObject(fabricRef.current);
      setLayers([...layers].reverse());
      fabricRef.current.requestRenderAll();
    } catch (error) {
      console.error('Error updating layers:', error);
    }
  }, [fabricRef, setLayers, getImagePreview, filterObjects]);

  useEffect(() => {
    const canvas = fabricRef.current;
    if (!canvas) return;

    const eventHandlers = {
      'mouse:down': (options: fabric.IEvent) => {
        handleLassoMouseDown(options);
        handleCanvasMouseDown({
          options,
          canvas: canvas,
          selectedShapeRef,
          isDrawing: isDrawingRef,
          shapeRef,
          activeObjectRef,
        });
      },
      'mouse:move': (options: fabric.IEvent) => {
        handleLassoMouseMove(options);
        handleCanvasMouseMove({
          options,
          canvas: canvas,
          isDrawing: isDrawingRef,
          selectedShapeRef,
          shapeRef,
          syncShapeInStorage,
        });
      },
      'mouse:up': (options: fabric.IEvent) => {
        handleLassoMouseUp(options),
          handleCanvasMouseUp({
            canvas: canvas,
            isDrawing: isDrawingRef,
            shapeRef,
            activeObjectRef,
            selectedShapeRef,
            syncShapeInStorage,
            setActiveTool,
          });
      },
      'path:created': (options: fabric.IEvent) =>
        handlePathCreated({ options, syncShapeInStorage }),
      'object:modified': (options: fabric.IEvent) => {
        preventLayerUpdate.current = true;
        clearGuidelines(canvas);
        preventLayerUpdate.current = false;
        handleCanvasObjectModified({
          options,
          setElementAttributes,
          syncShapeInStorage,
        });
      },
      'object:removed': (options: fabric.IEvent) => {
        if (preventLayerUpdate.current) return;
        updateLayers();
      },
      'object:added': (options: fabric.IEvent) => {
        if (preventLayerUpdate.current) return;
        updateLayers();
      },
      'selection:cleared': () => {
        activeObjectRef.current = null;
        setCanvasData({ activeObject: null, isEditing: false });
      },
      'object:moving': (options: fabric.IEvent) => {
        const modifiedObject = options.target;
        if (modifiedObject) {
          activeObjectRef.current = modifiedObject;
        }
        handleCanvasObjectMoving({ options });
        preventLayerUpdate.current = true;
        handleObjectSnap({
          options: options.target,
          canvas,
        });
        preventLayerUpdate.current = false;
      },
      'selection:created': (options: fabric.IEvent) => {
        handleObjectSelected(options);
        const selectedObject = (options as fabric.IEvent).selected?.[0];
        if (selectedObject) {
          setCanvasData({ activeObject: selectedObject });
        }

        handleCanvasSelectionCreated({
          options,
          isEditing: isEditing as boolean,
          setElementAttributes,
        });
      },
      'selection:updated': (options: fabric.IEvent) => {
        handleObjectSelected(options);
        const selectedObject = (options as fabric.IEvent).selected?.[0];
        if (selectedObject) {
          setCanvasData({ activeObject: selectedObject });
        }

        handleCanvasSelectionCreated({
          options,
          isEditing: isEditing as boolean,
          setElementAttributes,
        });
      },
      'object:scaling': (options: fabric.IEvent) =>
        handleCanvasObjectScaling({ options, setElementAttributes }),
    };

    Object.entries(eventHandlers).forEach(([event, handler]) => {
      canvas.on(event as any, handler as any);
    });

    return () => {
      Object.entries(eventHandlers).forEach(([event, handler]) => {
        canvas.off(event as any, handler as any);
      });
    };
  }, [
    activeObjectRef,
    fabricRef,
    handleLassoMouseDown,
    handleLassoMouseMove,
    handleLassoMouseUp,
    handleObjectSelected,
    isDrawingRef,
    isEditing,
    selectedShapeRef,
    setActiveTool,
    setCanvasData,
    updateLayers,
    setElementAttributes,
    shapeRef,
    syncShapeInStorage,
  ]);

  useEffect(() => {
    const canvas = fabricRef.current;
    if (!canvas) return;
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, fabricRef]);
};
