import { useRouter } from 'next/router';
import { useArtboardStore } from '@/state/useStore';
import { useShapesMutation } from '@/utils/canvas-lib/hooks/useShapesMutation';

export const useCanvasChange = () => {
  const { setActiveTab } = useArtboardStore();

  const deleteAllShapes = useShapesMutation(({ storage }: any) => {
    // get the canvasObjects store
    const canvasObjects = storage.getCanvasObjects();
    // if the store doesn't exist or is empty, return
    if (!canvasObjects || canvasObjects.size === 0) return true;

    // delete all the shapes from the store
    for (const [key, value] of canvasObjects.entries()) {
      canvasObjects.delete(key);
    }

    // return true if the store is empty
    return canvasObjects.size === 0;
  }, []);
  const router = useRouter();

  const handleCanvasChange = async (
    id?: string,
    options?: {
      hasSketch?: boolean;
    }
  ) => {
    deleteAllShapes({});

    setActiveTab('sketch');
    // let routeMethod = router.replace;

    // const finalUrl = queryString.stringifyUrl(
    //   {
    //     url: router.asPath,
    //     query: {
    //       sketchId: id,
    //     },
    //   },
    //   {
    //     skipEmptyString: true,
    //     skipNull: true,
    //   }
    // );

    // if (!options?.hasSketch) {
    //   routeMethod = router.push;
    //   sketch.fabricRef?.current?.clear();
    // }

    // await routeMethod(finalUrl, finalUrl, { shallow: true });
  };

  return handleCanvasChange;
};
