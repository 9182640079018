import { useGetAnimationQuery, AnimationStatus } from '@/state/query/animator';
import { useOrganization } from '@clerk/nextjs';
import Download from '@nex/icons/svg/misc/download-alt.svg';
import LogoMark from '@nex/icons/svg/logo-mark.svg';
import { CallToAction, Text, Flex, Heading } from '@nex/labs';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import styles from './page.module.scss';
import { Video } from './components/video';
import { createVideoSource } from './components/video/utils';
import Animation from '@nex/icons/svg/animator/animation.svg';
import { GenerativeLoading } from '@/components/misc/generative-loading';
import { ANIMATION_TIPS } from '@/components/misc/generative-loading/generative-tip';
import { usePostHog } from 'posthog-js/react';

export const Animator = () => {
  const router = useRouter();
  const { organization, isLoaded: isOrgLoaded } = useOrganization();
  const [canPoll, setCanPoll] = useState(true);
  const { isFetched, data } = useGetAnimationQuery(
    {
      id: router.query.id as string,
    },
    {
      refetchInterval: canPoll ? 5000 : false,
      enabled: !!router.query.id,
    }
  );
  const posthog = usePostHog();
  const isStatus = useCallback(
    (status: AnimationStatus) => {
      const hasId = !!router.query.id;
      const isMatch = isFetched && data?.animation?.status === status;
      return hasId && isMatch;
    },
    [isFetched, router.query.id, data?.animation?.status]
  );

  useEffect(() => {
    if (
      isFetched &&
      [AnimationStatus.COMPLETED, AnimationStatus.FAILED].includes(
        data?.animation?.status!
      )
    ) {
      setCanPoll(false);
    }
  }, [data?.animation?.status, isFetched]);

  return (
    <div className={styles.Animator}>
      <Flex.Row
        gap={8}
        className="absolute left-[32px] top-[88px] lg:hidden flex"
      >
        <LogoMark width="18px" height="auto" />
        <Heading.h4>Animator</Heading.h4>
      </Flex.Row>

      <div className={styles.AnimatorContent}>
        <RenderIf condition={!router.query.id}>
          <Flex.Column alignItems="center" justifyContent="center" gap={5}>
            <Animation />
            <Text className={styles.AnimatorContentIdleText} fontSize="18px">
              Transform your ideas into captivating motion
            </Text>
          </Flex.Column>
        </RenderIf>

        <RenderIf
          condition={isStatus(AnimationStatus.COMPLETED) && isOrgLoaded}
        >
          <Video animation={data?.animation} slug={organization?.slug} />
        </RenderIf>

        <RenderIf condition={isStatus(AnimationStatus.FAILED)}>
          <h1>Failed to generate animation</h1>
        </RenderIf>

        <RenderIf condition={isStatus(AnimationStatus.PROCESSING)}>
          <div className={styles.AnimatorContentLoading}>
            <SimulateLoader createdAt={data?.animation?.createdAt} />
          </div>
        </RenderIf>
      </div>

      <div className={styles.Footer}>
        <RenderIf condition={isStatus(AnimationStatus.COMPLETED)}>
          <CallToAction.button
            download={createVideoSource([
              organization?.slug,
              data?.animation?.animationKey,
            ])}
            onClick={() => {
              posthog.capture('download_animation', {
                animationId: data?.animation?.id,
              });
            }}
            leadingIcon={<Download width="18px" />}
          >
            Download
          </CallToAction.button>
        </RenderIf>
      </div>
    </div>
  );
};

const RenderIf = (props: { condition: boolean; children: React.ReactNode }) => {
  return <>{props.condition ? props.children : null}</>;
};

const SimulateLoader = ({ createdAt }: { createdAt?: string }) => {
  const totalDuration = 300000;
  const [progress, setProgress] = useState(() => {
    const elapsedTime = Date.now() - new Date(createdAt ?? '').getTime();

    return Math.min((elapsedTime / totalDuration) * 100, 100);
  });

  const simulatePromise = useCallback(() => {
    const elapsedTime = Date.now() - new Date(createdAt ?? '').getTime();
    const remainingTime = Math.max(totalDuration - elapsedTime, 0);

    // If time already elapsed, set to 100%
    if (remainingTime === 0) {
      setProgress(100);
      return;
    }

    const intervalId = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          clearInterval(intervalId);
          return 100;
        }
        // Calculate increment based on remaining time
        const increment = (1000 / remainingTime) * 100;
        return Math.min(oldProgress + increment, 100);
      });
    }, 1000);

    // Set timeout to ensure we reach 100% after exactly remainingTime
    setTimeout(() => {
      clearInterval(intervalId);
      setProgress(100);
    }, remainingTime);

    return () => {
      clearInterval(intervalId);
    };
  }, [createdAt]);

  useEffect(() => {
    const cleanup = simulatePromise();
    return cleanup;
  }, [simulatePromise]);

  return (
    <>
      <div className="mt-[50px] mb-8 flex flex-col gap-[32px]">
        <GenerativeLoading progress={progress} isResult tips={ANIMATION_TIPS} />
        <Flex.Column gap={8} alignItems="center">
          <Text className="text-center opacity-70" fontSize="14px">
            Estimated time remaining: 3 - 5 mins.
          </Text>

          <Text className="text-center" fontSize="14px" weight={700}>
            You can try out our other apps while we process this
          </Text>

          <Flex.Row gap={8}>
            <CallToAction.a
              outline
              size="xs"
              variant="clear"
              href="/canvas/new"
            >
              Canvas
            </CallToAction.a>
            <CallToAction.a
              outline
              size="xs"
              variant="clear"
              href="/artboard/new"
            >
              Studio
            </CallToAction.a>
            <CallToAction.a
              outline
              size="xs"
              variant="clear"
              href="/enhance/new"
            >
              Enhance
            </CallToAction.a>
          </Flex.Row>
        </Flex.Column>
      </div>
    </>
  );
};
