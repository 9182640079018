import Link from 'next/link';
import React from 'react';
import { useUser } from '@clerk/nextjs';
import {
  CallToAction,
  Flex,
  Heading,
  Tabs,
  Text,
  getDataIcons,
  memoWrapper,
} from '@nex/labs';
import { Collections, ContentLoader } from '..';
import { usePostHog } from 'posthog-js/react';
import LogoMark from '@nex/icons/svg/logo-mark.svg';
import { ArtBoards } from '../library/views/artboards';
import classNames from 'classnames';
import { ConsoleSlateActions } from '@/components/layout';
import { useUserStore } from '@/state/useStore';
import Bleed from '@nex/icons/svg/console/bleed.svg';
import styles from './console-home.module.scss';
import { SetupChecklist } from './components/setup-checklist';
import { AppIcon } from '@/components/misc/app-icon';

const activeActions = [
  {
    title: 'Create video from image',
    href: '/animator/new',
    key: 'animator',
  },
  {
    title: 'Map your story to characters',
    href: '/artboard/new',
    key: 'studio',
  },
  {
    title: 'Relight old image',
    href: '/enhance/new',
    key: 'enhancer',
  },
  // {
  //   title: 'Innovate with canvas',
  //   href: '/canvas/new',
  //   key: 'canvas',
  // },
];

export const Console = () => {
  const { user, isLoaded } = useUser();
  const posthog = usePostHog();
  const trackCTA = (name: string) => posthog.capture(`console_${name}_clicked`);
  const [currentTab, setCurrentTab] = React.useState('artboards');
  const { profile } = useUserStore();

  return (
    <div className={styles.ConsoleWrapper}>
      <Flex.Column
        gap={14}
        alignItems="center"
        justifyContent="center"
        fullWidth
        className="text-center mt-4 relative"
      >
        <Bleed className={styles.ConsoleBlurBleed} />
        {/* <a className={styles.ConsoleMessage} href="https://nex.art">
          <Text>
            Black Friday deal! <strong>Get 50% off yearly plans</strong>
          </Text>{' '}
          <img
            src={getDataIcons('arrow-thin-down', '#000')}
            alt="arrow"
            width={12}
            className="rotate-[-90deg]"
          />
        </a> */}
        <Flex gap={12} className="relative z-10">
          <LogoMark width="32" height="32" />
          <Heading.h4 fontSize="calc(var(--font-h3) + 6px)" weight={600}>
            Welcome{isLoaded && ','} {user?.firstName}
          </Heading.h4>
        </Flex>
        <Heading.h6 className="opacity-60">
          Let&apos;s create something amazing!
        </Heading.h6>

        {/* <Flex
          scrollable
          className={styles.ActiveActions}
          gap={8}
          alignItems="flex-start"
          justifyContent="center"
        >
          {activeActions.map((action, index) => (
            <CallToAction.a
              key={index}
              size="xs"
              variant="clear"
              href={action.href}
              onClick={() => trackCTA(action.title)}
              leadingIcon={
                <AppIcon type={action.key as any} color="#000" width={18} />
              }
            >
              {action.title}
            </CallToAction.a>
          ))}
        </Flex> */}
      </Flex.Column>

      <div className={styles.ConsoleActionSlate}>
        <ConsoleSlateActions />
      </div>

      <SetupChecklist onboardingChecklist={profile?.onboardingChecklist} />

      <Flex.Column gap={48} className={classNames([styles.ConsoleRecentWorks])}>
        {currentTab === 'artboards' && (
          <Flex.Column>
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <span>
                <Heading.h5 weight={700}>Recent Studio</Heading.h5>
              </span>

              <Link href={'/library/studio'}>
                <Text color="var(--primary-theme)" weight={700}>
                  View All Studio
                </Text>
              </Link>
            </Flex>

            <ArtBoards bare limit={4} />
          </Flex.Column>
        )}

        {currentTab === 'collections' && (
          <Flex.Column>
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="center"
              className="mb-5"
            >
              <span>
                <Heading.h5 weight={700}>Recent Collections</Heading.h5>
              </span>

              <Link href={'/library'}>
                <Text color="var(--primary-theme)" weight={700}>
                  View Collections
                </Text>
              </Link>
            </Flex>

            <Collections bare limit={6} />
          </Flex.Column>
        )}

        <div className={styles.ConsoleBannerArea}>
          <Flex.Column gap={4}>
            <span className={styles.ConsoleBannerArea__badge}>
              <span>NEW</span>
              Collaborate
            </span>
            <Heading.h4 weight={700} className="mt-4">
              Collaborate workspaces for your team.
            </Heading.h4>
            <Text weight={600} className="max-w-[390px]">
              Collaboration made easy with your team on NEX Artboard. Learn how
              nex can help your team.
            </Text>
            <CallToAction.a
              variant="secondary"
              className="mt-4"
              outline
              href="/artboard/new"
            >
              Learn More
            </CallToAction.a>
          </Flex.Column>

          <img src="/images/dashboard/collaborate.png" alt="Hero" />
        </div>
      </Flex.Column>
    </div>
  );
};
