import type { PropsWithChildren } from 'react';
import React, { use, useEffect, useMemo, useState } from 'react';

import {
  CallToAction,
  Flex,
  SkipNavContent,
  SkipNavLink,
  Text,
  isPremiumUser,
  transitions,
  useHover,
  Tooltip,
  getDataIcons,
} from '@nex/labs';
import { useUser } from '@clerk/nextjs';

import Router, { useRouter } from 'next/router';
import { motion } from 'framer-motion';

import { useGlobalStore, useUserStore } from '@/state/useStore';
import styles from './console-layout.module.scss';
import { ConsoleHeaderNav } from './component/console-header-nav';

export const ConsoleLayout: React.FC<
  {
    title?: string;
    bare?: boolean;
  } & PropsWithChildren
> = ({ children, title }) => {
  const router = useRouter();
  const [openNav, setOpenNav] = React.useState(false);
  const getActive = (href: string) => {
    return router.pathname === href ? styles.active : '';
  };
  const { setOverlayLoading, setModal } = useGlobalStore();
  const getSlideIn = transitions.useSlide();
  const { user } = useUser();

  const { profile } = useUserStore();

  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      setOpenNav(false);
    });
  }, []);

  useEffect(() => {
    const redirectTo = window.sessionStorage.getItem('redirectTo');

    if (redirectTo) {
      setOverlayLoading({
        loading: true,
        message: 'Preparing your artboard and Redirecting...',
      });

      if (profile?.id || user) {
        setTimeout(() => {
          Router.push(redirectTo);
          window.sessionStorage.removeItem('redirectTo');
          setOverlayLoading(false);
        }, 1200);
      }
    }
  }, [profile?.id, user]);

  useEffect(() => {
    if (typeof window !== 'undefined')
      document.documentElement.style.setProperty(
        '--feedback-area-left',
        'var(--sidebar-width)'
      );

    return () => {
      if (typeof window !== 'undefined')
        document.documentElement.style.setProperty(
          '--feedback-area-left',
          '0px'
        );
    };
  }, []);

  const { hoverProps } = useHover({
    onHoverStart: (e) => setOpenNav(true),
    onHoverEnd: (e) => setOpenNav(false),
  });

  return (
    <div className={`${styles.ConsoleLayout}`}>
      <SkipNavLink focusSelector="maincontent" />

      <div className={styles.ConsoleLayoutMain}>
        <div className={styles.ConsoleLayoutMainNav}>
          <ConsoleHeaderNav />
        </div>

        <motion.main
          {...getSlideIn({ x: 3 })}
          className={styles.ConsoleLayoutMainContent}
        >
          <SkipNavContent focusSelector="maincontent">
            <div className={styles.MainContent}>{children}</div>
          </SkipNavContent>
        </motion.main>
      </div>
    </div>
  );
};
