import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from '@vidstack/react/player/layouts/default';
import type { Animation } from '@/state/query/animator';
import { createVideoSource } from './utils';

type VideoProps = {
  animation?: Animation | null;
  slug?: string | null;
};

export const Video = (props: VideoProps) => {
  const { slug, animation } = props;
  const videoSource = createVideoSource([slug, animation?.animationKey]);

  return (
    <MediaPlayer muted playsInline src={videoSource}>
      <MediaProvider />
      <DefaultVideoLayout icons={defaultLayoutIcons} />
    </MediaPlayer>
  );
};
