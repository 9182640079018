import {
  Flex,
  Heading,
  useFeedback,
  CallToAction,
  Text,
  getDataIcons,
} from '@nex/labs';
import { useRouter } from 'next/router';
import Link from 'next/link';
import classNames from 'classnames';
import { useFileUpload } from '@/components/upload/useUpload';

import styles from './console-actions.module.scss';
import { AppIcon } from '@/components/misc/app-icon';

const slateActions = [
  {
    title: 'Studio',
    description: 'Ideate at the speed of thought',
    href: '/artboard/new',
    icon: 'studio',
    backdrop: '/images/dashboard/console/studio.png',
  },
  {
    title: 'Image Enhancer',
    description: 'Creatively upscale images',
    href: '/enhance',
    icon: 'enhancer',
    backdrop: '/images/dashboard/console/enhance.png',
  },
  {
    title: 'Animator',
    description: 'Animate your images',
    href: '/animator',
    icon: 'animator',
    backdrop: '/images/dashboard/console/animator.png',
  },
  // {
  //   title: 'Canvas',
  //   description: 'Creatively upscale images',
  //   href: '/canvas',
  //   icon: 'canvas',
  //   backdrop: '/images/dashboard/console/canvas.png',
  // },
];
export const ConsoleSlateActions = () => {
  const router = useRouter();
  const { onFileUpload } = useFileUpload();
  const isNotConsole = router.asPath === '/';

  return (
    <div
      className={classNames([
        styles.ConsoleActionSlateButtons,
        !isNotConsole && styles.sm,
      ])}
    >
      {!isNotConsole && (
        <>
          <div
            className={classNames([
              styles.ConsoleActionSlateButton,
              styles.LightVersion,
            ])}
          >
            <img src="/images/dashboard/upload.png" alt="Assets" />
            <Flex.Column gap={4}>
              <Heading.h5 weight={700}>Upload Assets</Heading.h5>
              <Text color="#475467">
                Drag & drop or upload up to 10mb in images
              </Text>
              <CallToAction.input
                variant="secondary"
                className="mt-2"
                defaultBehavior={false}
                multiple
                onFileUpload={onFileUpload}
              >
                Upload Images
              </CallToAction.input>
            </Flex.Column>
          </div>
        </>
      )}

      {slateActions.map((action) => (
        <Link
          key={action.title}
          href={action.href}
          className={styles.ConsoleActionSlateButton}
          style={{
            backgroundImage: `url(${action.backdrop})`,
          }}
        >
          <Flex.Column gap={4}>
            <Flex alignItems="center" gap={8}>
              <AppIcon type={action.icon as any} width={24} height="auto" />
              <Heading.h3 weight={700}>{action.title}</Heading.h3>
            </Flex>

            <Text className="mb-[24px]">{action.description}</Text>
            <CallToAction className="mt-2" variant="secondary">
              <img src={getDataIcons('add', '#fff')} alt="plus" />
              <span
                className={classNames([
                  styles.CallToActionText,
                  styles.NewActionText,
                ])}
              >
                New {action.title}
              </span>
            </CallToAction>
          </Flex.Column>
        </Link>
      ))}

      {/* <Link href="/artboard/new" className={styles.ConsoleActionSlateButton}>
        <Flex.Column gap={4}>
          <Flex alignItems="center" gap={4}>
            <AppIcon type="studio" width={24} />
            <Heading.h5 weight={700}>Studio</Heading.h5>
          </Flex>

          <Text>Ideate at the speed of thought</Text>
          <CallToAction className="mt-2">New Artboard</CallToAction>
        </Flex.Column>
      </Link>
      <Link href="/enhance" className={styles.ConsoleActionSlateButton}>
        <Flex.Column gap={4}>
          <Heading.h5 weight={700}>Image Enhancer</Heading.h5>
          <Text>Creatively upscale images</Text>
          <CallToAction className="mt-2">Enhance Image</CallToAction>
        </Flex.Column>
      </Link>
      <Link href="/animator" className={styles.ConsoleActionSlateButton}>
        <Flex.Column gap={4}>
          <Heading.h5 weight={700}>Animator</Heading.h5>
          <Text>Animate your images</Text>
          <CallToAction className="mt-2">New Animation</CallToAction>
        </Flex.Column>
      </Link>
      <Link href="/canvas" className={styles.ConsoleActionSlateButton}>
        <Flex.Column gap={4}>
          <Heading.h5 weight={700}>Canvas</Heading.h5>
          <Text>Creatively upscale images</Text>
          <CallToAction className="mt-2">New canvas</CallToAction>
        </Flex.Column>
      </Link> */}
    </div>
  );
};
