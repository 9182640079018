import {
  CallToAction,
  Flex,
  Heading,
  Text,
  useFeedback,
  Popover,
} from '@nex/labs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import Router, { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import analytics from '@lib/analytics';
import Zoom from '@nex/icons/svg/misc/zoom-in.svg';
import {
  useEnhanceMutation,
  useGetEnhanceSessionQuery,
} from '@/state/query/prompt';

import { useEnhanceStore } from '@/state/useStore';

import { GenerativeLoading } from '@/components/misc/generative-loading';
import { UPSCALE_TIPS } from '@/components/misc/generative-loading/generative-tip';

import DownloadIcon from '@nex/icons/svg/misc/download-alt.svg';
import TextIcon from '@nex/icons/svg/misc/edit.svg';
import Stacked from '@nex/icons/svg/misc/rows-2.svg';
import Structure from '@nex/icons/svg/misc/sidebar.svg';
import AIicon from '@nex/icons/svg/misc/sparkles.svg';

import { CreateCollectionModal } from '@/components/layout';
import { ContentLoader } from '../artboard';

import styles from './enhance.module.scss';
import { EnhanceSidebar } from './components/sidebar';
import { FloatBar } from '@/components/misc/float-bar';

export const Enhance = ({ id }: { id?: string }) => {
  const diviSorPane = React.useRef<HTMLDivElement>(null);
  const diviSor = React.useRef<HTMLButtonElement>(null);

  const router = useRouter();
  const { createToast } = useFeedback();

  const { setEnhanceData } = useEnhanceStore();
  const [imageLayout, setImageLayout] = React.useState('side-by-side');
  const [progress, setProgress] = useState(0);
  const [index, setIndex] = useState(0);
  const [collectionOpen, setCollectionOpen] = useState(false);
  const [scale, setScale] = useState(1);

  const containerRef = useRef(null);

  const posthog = usePostHog();

  const isInEnhance = useMemo(
    () => !!router.query.id && router?.query?.id !== 'new',
    [router.query.id]
  );

  const {
    data,
    isLoading: queryIsLoading,
    fetchStatus,
    refetch,
  } = useGetEnhanceSessionQuery(
    {
      id: router.query.id as string,
    },
    {
      enabled: isInEnhance,
      onSuccess: () => {
        setProgress(100);
        const findIndex = data?.upscalerSession?.upscales.findIndex(
          (item: any) => item?.id === Router.query?.id
        );

        if (findIndex !== undefined && findIndex !== -1) {
          setIndex(findIndex);
        }
      },

      onError: (error: any) => {
        if (error?.statusCode === 401) {
          Router.replace('/enhance', undefined, {
            shallow: true,
          });
        }

        createToast({
          message: error?.message || 'Something went wrong',
          variant: 'error',
          lifespan: 'long',
        });
      },
    }
  );

  const triggerIntercom = useCallback(() => {
    const SurveyID = 39970492;
    if (typeof window === 'undefined') return;

    if (window.localStorage.getItem('intercom-survey') === `${SurveyID}`)
      return;

    if ((window as any).Intercom) {
      setTimeout(() => {
        window.localStorage.setItem('intercom-survey', `${SurveyID}`);
        (window as any).Intercom('startSurvey', SurveyID);
      }, 1000);
    }
  }, []);

  const {
    mutate: createPrompt,
    isLoading,
    isError,
  } = useEnhanceMutation({
    onSuccess: (data) => {
      setProgress(100);

      if (Router?.query?.id) {
        return refetch();
      }

      analytics.track('Created Upscale', {
        eventAction: `Created Upscale`,
        eventLabel: `Upscaled an image`,
        eventValue: `Upscaled an image`,
      });

      triggerIntercom();

      posthog.capture('enhancer_enhance_success');

      Router.replace(`/enhance/${data?.upscalerSession?.id}`, undefined, {
        shallow: true,
      });
    },
    onError: (error: any) => {
      posthog.capture('enhancer_enhance_error');
      createToast({
        message: error?.message || 'Something went wrong',
        variant: 'error',
        lifespan: 'medium',
      });
    },
  });

  const currentUpscaledImage = useMemo(
    () => data?.upscalerSession?.upscales?.[index],
    [index, data]
  );

  useEffect(() => {
    if (!isInEnhance) {
      setEnhanceData({
        prompt: '',
        negativeprompt: '',
        creativity: 50,
        image: {
          file: null,
          preview: '',
        },
      });
    }
  }, [isInEnhance, setEnhanceData]);

  useEffect(() => {
    const container = containerRef.current as unknown as HTMLElement;
    if (!container) return;

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
      const zoomIntensity = 0.1;
      const zoomFactor = Math.exp((-event.deltaY / 100) * zoomIntensity);

      setScale((prevScale) => {
        const newScale = prevScale * zoomFactor;
        const minScale = 0.3;
        const maxScale = 1;
        return Math.min(Math.max(newScale, minScale), maxScale);
      });
    };

    container.addEventListener('wheel', handleWheel, { passive: false });
    return () => container.removeEventListener('wheel', handleWheel);
  }, [containerRef.current]);

  const zoomToFit = useCallback(() => {
    if (diviSor.current && diviSorPane.current) {
      const image = diviSorPane.current.children[0] as HTMLImageElement;

      const imageContainer = diviSor.current.parentElement;

      if (!imageContainer) return;

      const containerWidth = imageContainer.clientWidth - 2 * 40;
      const containerHeight = imageContainer.clientHeight - 2 * 40;
      const imageWidth = image.naturalWidth;
      const imageHeight = image.naturalHeight;

      const widthRatio = containerWidth / imageWidth;
      const heightRatio = containerHeight / imageHeight;
      const newScale = Math.min(widthRatio, heightRatio);

      setScale(Math.min(newScale, 1));
    }
  }, [diviSor.current, diviSorPane.current, index]);

  useEffect(() => {
    zoomToFit();
    window.addEventListener('resize', zoomToFit);
    return () => window.removeEventListener('resize', zoomToFit);
  }, [zoomToFit]);

  const handleDivisorDrag = useCallback(
    (e: any) => {
      e.preventDefault();
      e.currentTarget.style.cursor = 'grabbing';

      if (diviSor.current && diviSorPane.current) {
        const imageContainer = diviSor.current.parentElement;
        const image = diviSorPane.current.children[0] as HTMLImageElement;
        if (!imageContainer) return;

        const imageLeft = image.getBoundingClientRect().left;
        const imageWidth = imageContainer.clientWidth;

        const startX = e.clientX;
        const startLeft = diviSor.current.offsetLeft;

        const moveHandler = (moveEvent: MouseEvent) => {
          const mouseX = moveEvent.clientX;

          let newLeft = startLeft + (mouseX - startX);
          newLeft = Math.max(0, Math.min(newLeft, imageWidth));

          diviSor.current!.style.left = `${newLeft}px`;

          const imageStart =
            image.getBoundingClientRect().left -
            imageContainer.getBoundingClientRect().left;
          const recentLeft = Math.max(0, newLeft - imageStart);

          diviSorPane.current!.style.clipPath = `inset(0px ${Math.max(0, imageWidth - recentLeft / scale)}px 0px 0px)`;
        };

        const upHandler = () => {
          window.removeEventListener('mousemove', moveHandler);
          window.removeEventListener('mouseup', upHandler);
        };

        window.addEventListener('mousemove', moveHandler);
        window.addEventListener('mouseup', upHandler);
      }
    },
    [scale]
  );

  return (
    <div className={`${styles.Enhance}`}>
      <div className={styles.EnhanceWrapper}>
        <EnhanceSidebar
          currentUpscaledImage={currentUpscaledImage}
          sessionId={data?.upscalerSession?.id}
          createPrompt={createPrompt}
          isLoading={isLoading}
        />
        <FloatBar />
        <section
          style={{
            justifyContent:
              isLoading || (queryIsLoading && fetchStatus !== 'idle')
                ? 'space-between'
                : 'center',
          }}
        >
          <InitialLoader
            progress={progress}
            setProgress={setProgress}
            show={
              (isLoading || (queryIsLoading && fetchStatus !== 'idle')) &&
              !data?.upscalerSession?.upscales?.length
            }
          />

          {data && (
            <Flex
              justifyContent="space-between"
              className={`${!data && 'opacity-40'} ${styles.enhanceFooter}`}
            >
              <div className={styles.ActionBar}>
                <Popover hug>
                  <Popover.Trigger>
                    <button className="flex gap-2">
                      <Zoom className="w-[14px]" color="#000" />
                      <Text>{(scale * 100).toFixed(0)}%</Text>
                    </button>
                  </Popover.Trigger>
                  <Popover.Content isDropdown>
                    <button onClick={zoomToFit}>Zoom to fit</button>
                    <button onClick={() => setScale(Math.min(1, scale + 0.1))}>
                      Zoom in
                    </button>
                    <button
                      onClick={() => setScale(Math.max(0.3, scale - 0.1))}
                    >
                      Zoom out
                    </button>
                    <button onClick={() => setScale(1)}>Reset</button>
                  </Popover.Content>
                </Popover>
                <button
                  onClick={() => {
                    setImageLayout('side-by-side');
                  }}
                  disabled={!!!data}
                  className={classNames([
                    imageLayout === 'side-by-side' && styles.active,
                  ])}
                >
                  <Structure />
                </button>

                <button
                  onClick={() => {
                    setImageLayout('stacked');
                  }}
                  disabled={!!!data}
                  className={classNames([
                    imageLayout === 'stacked' && styles.active,
                  ])}
                >
                  <Stacked />
                </button>
              </div>

              {data?.upscalerSession?.upscales?.length && (
                <div className={styles.OtherImages}>
                  {(isLoading || queryIsLoading) && (
                    <button onClick={() => setIndex(-1)}>
                      <ContentLoader className="h-[40px] w-[40px]" />
                    </button>
                  )}

                  {data?.upscalerSession?.upscales?.map(
                    (upScl: any, i: number) => (
                      <button
                        onClick={() => setIndex(i)}
                        key={upScl?.after}
                        className={classNames([
                          index === i &&
                            'border-[var(--primary-theme)] border-solid border-[2px] rounded-[4px]',
                        ])}
                      >
                        <img src={upScl?.after} alt="images" />
                      </button>
                    )
                  )}
                </div>
              )}

              <div className={styles.ActionBar}>
                <CallToAction
                  download={currentUpscaledImage?.after}
                  variant="clear"
                  disabled={!!!data}
                  size="sm"
                  leadingIcon={<DownloadIcon width={14} />}
                >
                  Save
                </CallToAction>
              </div>
            </Flex>
          )}

          <HowToUseEnhance show={!data && !isLoading && !Router.query?.id} />

          {data && currentUpscaledImage ? (
            <>
              {imageLayout === 'side-by-side' ? (
                <div className={styles.ImageSection}>
                  <button
                    ref={diviSor}
                    className={styles.Divisor}
                    onMouseDown={handleDivisorDrag}
                    onMouseUp={(e) => {
                      e.currentTarget.style.cursor = 'grab';
                    }}
                  />
                  <div
                    ref={containerRef}
                    className="relative overflow-hidden w-full flex justify-center items-center"
                    style={{
                      height: 'calc(100vh - 100px)',
                    }}
                  >
                    <div
                      style={{
                        transform: `scale(${scale})`,
                        transformOrigin: 'center center',
                        transition: 'transform 0.1s linear',
                      }}
                      className="relative inline-block"
                    >
                      <figure>
                        <img
                          className={styles.ImgBase}
                          src={currentUpscaledImage?.after}
                          alt="final image"
                        />

                        <div className={styles.ImageDivisor} ref={diviSorPane}>
                          <img
                            src={currentUpscaledImage?.before}
                            className="border-[var(--primary-gray)] border-solid border-[1px]"
                          />
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
              ) : (
                <Flex
                  flexWrap="wrap"
                  gap={18}
                  alignItems="center"
                  className="min-h-[100%] lg:min-h-[700px]"
                >
                  <div className="max-w-[100%] xl:max-w-[49%] w-full h-fit text-center">
                    <Flex.Column gap={8} className="mb-4">
                      <Heading.h5 weight={800}>Original</Heading.h5>
                      <Text
                        fontSize="var(--font-caption)"
                        className="opacity-60"
                      ></Text>
                    </Flex.Column>
                    <img
                      src={currentUpscaledImage?.before}
                      className="w-full h-fit max-h-[670px] object-contain"
                    />
                  </div>

                  <div className="max-w-[100%] xl:max-w-[49%] w-full h-fit text-center">
                    <Flex.Column gap={8} className="mb-4">
                      <Heading.h5 weight={800}>Enhanced</Heading.h5>
                      <Text
                        fontSize="var(--font-caption)"
                        className="opacity-60"
                      ></Text>
                    </Flex.Column>
                    <img
                      src={currentUpscaledImage?.after}
                      alt="final image"
                      className="w-full h-fit max-h-[670px] object-contain"
                    />
                  </div>
                </Flex>
              )}
            </>
          ) : isLoading && data ? (
            <div className={styles.ImageSection}>
              <ContentLoader className="h-[95vh] w-full rounded-[20px]" />
            </div>
          ) : null}
        </section>
      </div>
      <CreateCollectionModal
        data={data?.upscalerSession?.upscales?.[index]}
        show={collectionOpen}
        onClose={() => setCollectionOpen(false)}
      />
    </div>
  );
};

const HowToUseEnhance = ({ show }: { show: boolean }) => {
  return (
    <>
      {show && (
        <div className={styles.ImageSection}>
          <>
            <Heading.h5 weight={800} className="mt-14 mb-8" align="center">
              How to use Enhance
            </Heading.h5>
            <Flex
              flexWrap="wrap"
              gap={22}
              alignItems="stretch"
              className="mb-10 mr-auto ml-auto w-fit"
            >
              <div className="max-w-[300px] p-5  rounded-[8px] bg-[var(--primary-white)]">
                <span className="w-[55px] h-[55px] bg-[#e1e1e1] rounded-[50%] flex items-center justify-center">
                  <DownloadIcon />
                </span>
                <Heading.h4 className="mt-6 mb-2">
                  Click on &ldquo;Choose File&ldquo;
                </Heading.h4>
                <Text className="opacity-80">
                  Click on &ldquo;Choose File&ldquo;and select the image you
                  want to enhance.
                </Text>
              </div>
              <div className="max-w-[300px] p-5  rounded-[8px] bg-[var(--primary-white)]">
                <span className="w-[55px] h-[55px] bg-[#e1e1e1] rounded-[50%] flex items-center justify-center">
                  <TextIcon />
                </span>
                <Heading.h4 className="mt-6 mb-2">Enter a prompt</Heading.h4>
                <Text className="opacity-80">
                  Enter a prompt to describe the image you want to create.
                </Text>
              </div>
              <div className="max-w-[300px] p-5  rounded-[8px] bg-[var(--primary-white)]">
                <span className="w-[55px] h-[55px] bg-[var(--primary-white)] rounded-[50%] flex items-center justify-center">
                  <AIicon />
                </span>
                <Heading.h4 className="mt-6 mb-2">Click Enhance</Heading.h4>
                <Text className="opacity-80">
                  Click Enhance to start the process. You can also add a
                  negative prompt to help the model.
                </Text>
              </div>
            </Flex>
          </>
        </div>
      )}
    </>
  );
};

const InitialLoader = ({
  progress,
  setProgress,
  show,
}: {
  progress: number;
  setProgress: any;
  show: boolean;
}) => {
  const simulatePromise = useCallback(() => {
    if (!show) return;
    setProgress(0);
    const promiseDuration = !show ? 1500 : 180000;
    const myPromise = new Promise((resolve) => {
      setTimeout(resolve, promiseDuration);
    });

    const intervalId = setInterval(() => {
      if (!show) clearInterval(intervalId);
      else
        setProgress((oldProgress: number) => {
          if (oldProgress >= 100) {
            clearInterval(intervalId);
            return 100;
          }

          return oldProgress + (1000 / promiseDuration) * 100;
        });
    }, 1000);

    myPromise
      .then(() => {
        clearInterval(intervalId);
        setProgress(100);
      })
      .catch(() => {
        clearInterval(intervalId);
        setProgress(0);
      });

    if (!show) {
      setProgress(100);
      clearInterval(intervalId);
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      simulatePromise();
    }
  }, [show, simulatePromise]);

  return (
    <>
      {show && (
        <div className="mt-[50px] mb-8">
          <GenerativeLoading progress={progress} isResult tips={UPSCALE_TIPS} />
        </div>
      )}
    </>
  );
};
