import {
  CallToAction,
  Field,
  Flex,
  Text,
  formDataAppender,
  getDataIcons,
  Slider,
  Tooltip,
  Tabs,
  useFeedback,
} from '@nex/labs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import { motion } from 'framer-motion';

import { useGlobalStore, useEnhanceStore } from '@/state/useStore';

import LogoMark from '@nex/icons/svg/logo-mark.svg';
import UploadIcon from '@nex/icons/svg/dashboard/image.svg';

import styles from '../enhance.module.scss';
export const EnhanceSidebar = ({
  currentUpscaledImage,
  sessionId,
  createPrompt,
  isLoading,
}: {
  currentUpscaledImage: any;
  sessionId: string;
  createPrompt: any;
  isLoading: boolean;
}) => {
  const router = useRouter();
  const { createToast } = useFeedback();
  const { setModal } = useGlobalStore();
  const { enhanceData: formDetails, setEnhanceData } = useEnhanceStore();

  const [activeTab, setActiveTab] = useState('enhance');

  const posthog = usePostHog();

  const handleOnChange = useCallback(
    (e: any) => {
      setEnhanceData({
        [e.target.name]: e.target.value,
      });
    },
    [setEnhanceData]
  );

  const isInEnhance = useMemo(
    () => !!router.query.id && router?.query?.id !== 'new',
    [router.query.id]
  );

  useEffect(() => {
    if (!isInEnhance) {
      setEnhanceData({
        prompt: '',
        negativeprompt: '',
        creativity: 50,
        image: {
          file: null,
          preview: '',
        },
      });
    }
  }, [isInEnhance, setEnhanceData]);

  return (
    <aside>
      <Flex.Column gap={18} className="h-full">
        <div className={styles.asideInert}>
          <Flex justifyContent="space-between" className="mb-2">
            <Flex gap={8} alignItems="center">
              <Link href="/">
                <LogoMark width="24" height="24" />
              </Link>
              <Text weight={800}>ENHANCE</Text>
            </Flex>
            <button
              className="text-right"
              onClick={() => {
                setModal({
                  type: 'tutorial',
                  props: {
                    link: 'https://www.youtube.com/embed/IG66KC01P_o?si=8yU-W3cX9btbNmV-',
                    title: 'How to use NEX Enhance',
                  },
                });
              }}
            >
              <Text.span weight={700} color="var(--primary-theme)">
                <img
                  src={getDataIcons('help', '#30d95e')}
                  className="inline -mt-[4px]"
                  width={12}
                />{' '}
                <Text.span
                  fontSize="13px"
                  weight={600}
                  className="hidden xl:inline"
                >
                  Tutorial
                </Text.span>
              </Text.span>
            </button>
          </Flex>

          <span>
            <Text weight={600} className="mb-3">
              Image
            </Text>
            <div className={styles.AssetUpload}>
              {formDetails?.image?.preview ||
              currentUpscaledImage?.before ||
              Router.query?.img ? (
                <>
                  <img
                    src={
                      formDetails?.image?.preview ||
                      currentUpscaledImage?.before ||
                      decodeURIComponent(Router.query.img as string)
                    }
                    alt="uploaded image"
                    className="w-full max-h-[200px] object-cover"
                  />
                </>
              ) : (
                <>
                  <Flex.Column gap={8} alignItems="center">
                    <UploadIcon />
                    <Text weight={700}>Upload images</Text>
                    <Text>upload up to 10mb PNG/JPEG</Text>
                  </Flex.Column>
                </>
              )}

              <CallToAction.input
                size="sm"
                variant="secondary"
                className="w-full"
                onFileUpload={(file, options) => {
                  setEnhanceData({
                    image: {
                      file: file[0] as File,
                      preview: options!.previews?.[0] as string,
                    },
                  });
                }}
                multiple
                defaultBehavior={false}
                leadingIcon={
                  <img
                    src={getDataIcons('add', '#000')}
                    className="w-[12px] h-[12px]"
                  />
                }
              >
                {Router.query?.img || currentUpscaledImage?.before
                  ? 'Choose another file'
                  : 'Choose File'}
              </CallToAction.input>
            </div>
          </span>
          <Flex.Column gap={8} className="mt-4" fullWidth>
            <Tabs
              defaultTab={activeTab}
              size="sm"
              onChangeTab={(tab) => {
                if (tab === 'upscale') {
                  createToast({
                    message:
                      'Upscaling is a coming soon feature. Stay tuned for updates!',
                  });
                  return;
                }
                setActiveTab(tab);
              }}
            >
              <Tabs.Tab title="Enhance" dataKey="enhance" />
              <Tabs.Tab title="Upscale" dataKey="upscale" disabled />
            </Tabs>
            <Text
              className="opacity-70"
              fontSize="var(--font-caption)"
              align="center"
            >
              {activeTab === 'upscale'
                ? ' Increase image size or clarify details.'
                : 'Enhance with prompts & adjust creativity.'}
            </Text>
          </Flex.Column>
          <Field.Textarea
            label={
              <>
                <Flex.Row gap={4} className="items-center">
                  <Text weight={600}>Description</Text>
                  <Tooltip content="Where you can see all of the blocks being used for your generation">
                    <img
                      src={getDataIcons('help')}
                      className="inline w-[12px] -mt-[2px]"
                    />
                  </Tooltip>
                </Flex.Row>
              </>
            }
            name="prompt"
            value={formDetails.prompt}
            placeholder="Describe the image"
            onChange={handleOnChange}
          />
          {activeTab === 'upscale' ? (
            <motion.div
              initial={{ opacity: 0, y: 4 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              key="upscale"
            >
              <Flex.Column gap={8}>
                <>
                  <Flex.Row gap={4} className="items-center">
                    <Text weight={600}>Upscale Amount</Text>
                    <Tooltip content="A higher multiplier increases the size and detail of the output. Choose 1x if you want to enhance the image without changing its original resolution.">
                      <img
                        src={getDataIcons('help')}
                        className="inline w-[12px] -mt-[2px]"
                      />
                    </Tooltip>
                  </Flex.Row>
                </>
                <div className={styles.upscaleTabs}>
                  {Array.from({ length: 4 }).map((_, i) => {
                    const upscale = Math.pow(2, i);
                    return (
                      <button
                        key={i}
                        className={classNames([
                          styles.upscaleTab,
                          formDetails.upscale === upscale && styles.active,
                        ])}
                        onClick={() => {
                          setEnhanceData({
                            upscale,
                          });
                        }}
                      >
                        {upscale}x
                      </button>
                    );
                  })}
                </div>
                <Text
                  weight={600}
                  className=" opacity-60"
                  fontSize="var(--font-caption)"
                  align="center"
                >
                  Max resolution 2048px
                </Text>
              </Flex.Column>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0, y: 4 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              key="enhance"
            >
              <Flex.Column gap={18}>
                <div className="my-2">
                  <Slider
                    label={
                      <>
                        <Flex.Row gap={4} className="items-center ">
                          <Text weight={600} className="-mb-[8px]">
                            Creativity
                          </Text>
                          <Tooltip content="Adjust how much creative interpretation the enhancer should apply to your image. Lower settings will keep the image closer to its original form, while higher settings introduce more artistic changes.">
                            <img
                              src={getDataIcons('help')}
                              className="inline w-[12px] mt-[6px]"
                            />
                          </Tooltip>
                        </Flex.Row>
                      </>
                    }
                    value={formDetails.creativity}
                    minValue={10}
                    maxValue={85}
                    tooltip={`${formDetails!.creativity?.toFixed(0)}%`}
                    onChange={(e) => {
                      setEnhanceData({
                        creativity: e as number,
                      });
                    }}
                  />
                </div>
                <Field.Textarea
                  label="Negative Prompt"
                  placeholder="Negative Prompt"
                  name="negativeprompt"
                  value={formDetails.negativeprompt}
                  onChange={handleOnChange}
                />
              </Flex.Column>
            </motion.div>
          )}
        </div>
        <div className="mt-auto">
          <Text
            fontSize="12px"
            align="center"
            className="mb-[4px] opacity-80 mt-[32px]"
          >
            Upscaled 2x by default
          </Text>
          <CallToAction
            size="block"
            isLoading={isLoading}
            onClick={(e) => {
              const { image, negativeprompt, prompt, creativity } =
                formDetails || {};
              posthog.capture('enhancer_enhance_started');
              createPrompt(
                formDataAppender({
                  image:
                    image?.file ||
                    decodeURIComponent(Router.query.img as string),
                  prompt,
                  upscalerSessionId: sessionId,
                  creativity: creativity! / 100,
                  negativeprompt,
                })
              );
            }}
          >
            Enhance
          </CallToAction>
        </div>
      </Flex.Column>
    </aside>
  );
};
