import { createSmartApi } from '@lib/smart-query';
import { Pagination } from '@nex/types';
import queryString from 'query-string';

export enum AnimationStatus {
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export type Animation = {
  id: string;
  taskId: string;
  status: AnimationStatus;
  updatedAt: string; // ISO 8601 timestamp
  createdAt: string; // ISO 8601 timestamp
  description: string | null;
  imageKey: string;
  model: string;
  duration: string;
  cameraMovement: string | null;
  specialEffect: string | null;
  animationKey?: string;
  thumbnailKey: string | null;
  mimeType?: string; // e.g., "video/mp4"

  user: {
    id: string;
    name: string;
    photo: string;
  };

  workspace: {
    id: string;
    name: string;
    logo: string;
  };
};

export const animatorApiCreator = createSmartApi({
  key: 'animator',
  endpoints: (builder) => ({
    getAnimation: builder.query<
      any,
      {
        animation: Animation;
      }
    >({
      key: 'animation',
      queryFn: ({ id }) => ({
        url: `/v2/animations/${id}`,
      }),
    }),

    getAnimations: builder.query<
      {
        search?: string;
        limit?: number;
        shared?: boolean;
        workspaceId?: string;
      },
      {
        animations: Animation[];
        pagination: Pagination;
      }
    >({
      key: 'animations',
      isInfinite: true,
      queryFn: ({ search, limit, shared }) => ({
        url: queryString.stringifyUrl(
          {
            url: `/v2/animations`,
            query: {
              search,
              limit: limit || 20,
              shared,
            },
          },
          {
            skipNull: true,
            skipEmptyString: true,
          }
        ),
      }),
    }),

    animate: builder.mutation<
      any,
      {
        animation: Animation;
      }
    >({
      key: 'animate',
      mutationFn: (data) => {
        return {
          url: `/v2/animations`,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetAnimationQuery,
  useAnimateMutation,
  useGetAnimationsQuery,
} = animatorApiCreator;
