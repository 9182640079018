import { Flex, Text } from '@nex/labs';
import Link from 'next/link';

import LogoMark from '@nex/icons/svg/logo-mark.svg';
import styles from './sidebar.module.scss';
import { AnimateForm } from '@/features/console/animator/index';

export const AnimatorSidebar = () => {
  return (
    <div className={styles.AnimatorSidebar}>
      <Flex gap={8} alignItems="center">
        <Link href="/console">
          <LogoMark width="24" height="24" />
        </Link>
        <Text weight={800}>ANIMATOR</Text>
      </Flex>

      <AnimateForm />
    </div>
  );
};
