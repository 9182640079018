import { useAuth, useOrganization, useOrganizationList } from '@clerk/nextjs';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import Link from 'next/link';

import {
  Avatar,
  Flex,
  Popover,
  Text,
  useFeedback,
  getDataIcons,
} from '@nex/labs';

import PlusIcon from '@nex/icons/svg/blocks/plus.svg';

import styles from './workspace-switcher.module.scss';
import { ContentLoader } from '@/features/console';

import classNames from 'classnames';

export const WorkspaceSwitcher = ({
  onShowCreateWorkspace,
}: {
  onShowCreateWorkspace(): void;
}) => {
  const router = useRouter();
  const auth = useAuth();
  const { organization, isLoaded: organizationIsLoaded } = useOrganization();
  const {
    setActive: setActiveWorkspace,
    isLoaded: organizationListIsLoaded,
    userMemberships,
  } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
  });

  const isActiveWorkspace = useCallback(
    (id: string) => {
      return auth?.orgId === id;
    },
    [auth]
  );

  if (!organizationIsLoaded || !organization?.id) {
    return <ContentLoader className="w-full h-[48px] " />;
  }

  return (
    <Flex className={styles.WorkspaceSwitcher} gap={8} alignItems="center">
      <Popover size="md" outSet="block">
        <Popover.Trigger>
          <Flex alignItems="center" justifyContent="space-between" gap={12}>
            <Flex alignItems="center" gap={8}>
              <Avatar
                size={28}
                alt={organization?.name || 'Workspace'}
                src={organization?.imageUrl}
                rounded={false}
              />
              <Text weight={500} noOfLines={1}>
                {organization?.name}
              </Text>
            </Flex>
            <img
              alt="arrow-down"
              src={getDataIcons('arrow-thin-down', '#000')}
              className="w-2 h-2 mr-2"
            />
          </Flex>
        </Popover.Trigger>
        <Popover.Content isDropdown>
          {organizationListIsLoaded && (
            <>
              {userMemberships?.data.map(({ organization, role }) => {
                const isActive = isActiveWorkspace(organization.id);

                return (
                  <Flex
                    alignItems="center"
                    key={organization.id}
                    justifyContent="space-between"
                    className="py-px"
                  >
                    <Flex.Row alignItems="center" gap={8}>
                      <Avatar
                        alt={organization.name}
                        src={organization.imageUrl}
                        rounded={false}
                      />
                      <button
                        type="button"
                        onClick={() => {
                          if (auth.orgId !== organization.id) {
                            setActiveWorkspace({
                              organization: organization.id,
                            }).then(() => {
                              router.reload();
                            });
                          }
                        }}
                      >
                        <Flex.Column
                          flex="1"
                          className={styles.ConsoleLayoutAsideMetaName}
                        >
                          <Text
                            weight={600}
                            noOfLines={1}
                            className={classNames(isActive && '-mb-0.5')}
                          >
                            {organization.name}
                          </Text>
                          {isActive && (
                            <Text
                              as="span"
                              fontSize="12px"
                              className="opacity-50"
                              weight={600}
                            >
                              {role.split(':')[1]}
                            </Text>
                          )}
                        </Flex.Column>
                      </button>
                    </Flex.Row>
                    {isAdmin(role) && isActive && (
                      <Link href="/settings/workspace">
                        <img
                          src={getDataIcons('settings', '#000')}
                          alt="arrow-down"
                          className="w-4 h-4 mr-2 block ml-auto"
                        />
                      </Link>
                    )}
                  </Flex>
                );
              })}

              <Flex className="mt-2 sticky -bottom-[16px] bg-white p-[10px 10px] p-[10px] mx-[-16px] mb-[-16px]">
                {/* {userMemberships?.data.length < 2 && ( */}
                <button
                  type="button"
                  onClick={() => {
                    // if (userMemberships?.data.length >= 2) {
                    // return createToast({
                    //   variant: 'error',
                    //   message: 'You can only create 2 workspaces',
                    // });
                    // }

                    return onShowCreateWorkspace();
                  }}
                >
                  <Flex.Row alignItems="center" gap={8}>
                    <PlusIcon
                      className="[
                &>path
              ] text-[var(--primary-theme)] stroke-current
              ]"
                    />
                    <Text weight={600}>Create workspace</Text>
                  </Flex.Row>
                </button>
                {/* )} */}
              </Flex>
            </>
          )}
        </Popover.Content>
      </Popover>
    </Flex>
  );
};

function isAdmin(role: string) {
  return role === 'org:admin';
}
