export type Tutorial = {
  title: string;
  message: string;
  containerId: string;
  tab?: string | null;
  image?: string | null;
  position: string;
};

export const Tutorials: Tutorial[] = [
  {
    title: 'Write a prompt',
    message: 'Blocks are how you create your composition. Try adding one',
    containerId: '#action-input',
    tab: 'models',
    position: 'bottom center',
    image: '/images/dashboard/tutorial/prompt.gif',
  },
  {
    title: 'Blocks Preview',
    message: 'Select a block to view all the parameters used to create',
    containerId: '#floating-nav-bottom',
    tab: 'models',
    position: 'bottom center',
    image: '/images/dashboard/tutorial/preview-2.png',
  },
  {
    title: 'Styles',
    message: 'Easily control image style by adding a Preset.',
    containerId: '#artboard-aside',
    tab: 'models',
    position: 'center right',
    image: '/images/dashboard/tutorial/preset.png',
  },
  {
    title: 'Use Assets Conditions',
    message: 'Upload and manage assets here. Begin by clicking on Choose File.',
    containerId: '#artboard-aside',
    tab: 'assets',
    position: 'top right',
    image: '/images/dashboard/tutorial/asset.png',
  },
  {
    title: 'Sketch your composition',
    message: 'Freely draw and design or import an image create your own asset.',
    containerId: '#artboard-aside',
    tab: 'sketch',
    position: 'center right',
    image: '/images/dashboard/tutorial/sketch.gif',
  },
  {
    title: 'Artboard History',
    message: 'View your Artboard’s generation history.',
    containerId: '#floating-nav-top',
    tab: 'history',
    position: 'top center',
    image: '/images/dashboard/tutorial/history.png',
  },
];

export const ANIMATOR_TUTORIALS: Tutorial[] = [
  {
    title: 'Upload Source image',
    message: 'Start your creative journey by uploading a PNG or JPEG image',
    containerId: '#animate-upload',
    tab: null,
    position: 'top right',
    image: null,
  },
  {
    title: 'Movement & Effect',
    message: 'Pick camera moves and effects to enhance your shot.',
    containerId: '#animate-camera-movement',
    tab: null,
    position: 'center right',
    image: null,
  },
  {
    title: 'Describe your idea',
    message: 'Add details about your vision or skip this step to explore.',
    containerId: '#animate-description-input',
    tab: null,
    position: 'center right',
    image: null,
  },
];

export const TUTORIALS_KEY = ['artboard', 'animator'];
export enum TUTORIALS {
  ARTBOARD = 'artboard',
  ANIMATOR = 'animator',
}
export const TUTORIAL_STORAGE_KEY = '[nex]:tutorial';
