import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { AnimatePresence, motion } from 'framer-motion';

import {
  CallToAction,
  Flex,
  Modal,
  Spinner,
  Text,
  transitions,
} from '@nex/labs';
import { aspectMap } from '../../utils/constants';
import { WeightSlider } from '../misc/weight-slider';

import { toDataUrl } from '@/utils/canvas-lib/shapes';
import { useArtboardStore } from '@/state/useStore';

export const CropModal = () => {
  const cropperRef = useRef(null);
  const {
    setBlock,
    removeBlock,
    closeModal,
    blockInView: blocks,
    modal,
    defaultConfig,
  } = useArtboardStore();
  const [imageURL, setImageURL] = React.useState('');
  const [hasCropped, setHasCropped] = React.useState(false);
  const block = useMemo(() => {
    return blocks?.find(
      (block: any) => block?.data?.subMeta === modal?.props?.blockId
    );
  }, [blocks, modal]);

  const show = useMemo(() => modal?.type === 'crop', [modal]);

  useEffect(() => {
    if (block?.data?.key) {
      const img = new Image();
      img.src = `${process.env.NEXT_PUBLIC_CANVAS_HOST}/${block.data.key}`;
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        setImageURL(`${process.env.NEXT_PUBLIC_CANVAS_HOST}/${block.data.key}`);
      };

      img.onerror = () => {
        toDataUrl(block?.data?.src, (url: string) => {
          setImageURL(url);
        });
      };

      return;
    }

    if (!block?.data?.src) return;
    toDataUrl(block?.data?.src, (url: string) => {
      setImageURL(url);
    });

    return () => {
      setImageURL('');
    };
  }, [block?.data?.src, block?.data?.key]);

  const [ratio1, ratio2] =
    aspectMap?.[defaultConfig?.resolution as keyof typeof aspectMap]?.split(
      ':'
    ) ?? [];

  const getFadeIn = transitions.useSlide();

  if (!modal) return null;

  return (
    <Modal
      in={!!show}
      onClose={async () => {
        if (modal?.props?.onClose) {
          try {
            await modal?.props?.onClose();
            closeModal();
          } catch (error) {
            console.error(error);
          }
        } else closeModal();
      }}
      title={modal?.props?.title}
      size={modal?.props?.size ?? 'lg'}
    >
      <AnimatePresence mode="sync">
        <motion.div
          key={`show-${show}`}
          {...getFadeIn({
            y: 40,
          })}
        >
          <Flex.Column gap={18} className="p-4">
            <Flex.Column gap={10}>
              <Text className="flex-auto" weight={700}>
                Crop Image
              </Text>
              {!imageURL && (
                <Spinner
                  size={24}
                  text="Loading Image..."
                  style={{ display: 'block' }}
                />
              )}
              <Cropper
                src={imageURL}
                style={{
                  height: '600px',
                  width: '100%',
                }}
                viewMode={1}
                dragMode={'move'}
                initialAspectRatio={
                  defaultConfig?.resolution
                    ? Number(ratio1) / Number(ratio2)
                    : 16 / 9
                }
                guides={true}
                cropBoxResizable={false}
                crop={async () => {}}
                ref={cropperRef}
              />
            </Flex.Column>

            <WeightSlider block={block} setBlock={setBlock} />

            <Flex.Row gap={12} justifyContent="space-between" className="mt-8">
              <CallToAction
                size="sm"
                variant="error"
                onClick={() => {
                  removeBlock(block!.meta, block?.data?.subMeta);
                  closeModal();
                }}
              >
                Delete Block
              </CallToAction>
              <Flex.Row gap={12} justifyContent="flex-end">
                <CallToAction
                  size="sm"
                  onClick={() => {
                    const cropper = (cropperRef.current as any)?.cropper;
                    const dataURL = cropper.getCroppedCanvas().toDataURL();

                    if (hasCropped) {
                      setBlock(block!.meta, {
                        ...block?.data,
                        buffer: dataURL,
                      });
                    }

                    setHasCropped(false);
                    closeModal();
                  }}
                >
                  Save
                </CallToAction>
                <CallToAction
                  size="sm"
                  variant="secondary"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Close
                </CallToAction>
              </Flex.Row>
            </Flex.Row>
          </Flex.Column>
        </motion.div>
      </AnimatePresence>
    </Modal>
  );
};
